import { ITextStyles, mergeStyles, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import CountUp from 'react-countup';
import { getTheme } from '@fluentui/react';


interface INumberDisplayProps {
    number: number;
    text: string;
    subtitle?: string;
    bgcolor?: string;
}

const NumberDisplay: React.FC<INumberDisplayProps> = ({ number, text, subtitle, bgcolor }) => {

    const theme = getTheme();

    const numberStyles: ITextStyles = {
        root: {
            fontSize: 48,
            fontWeight: 'bold',
            marginBottom: 8,
        },
    };



    const containerStyles = mergeStyles({
        textAlign: 'center',
        border: '1px solid ' + bgcolor ? bgcolor : theme.palette.white,
        padding: 20,
        minWidth: 300,
        boxShadow: theme.effects.elevation64,
        borderRadius: 8,
        backgroundColor: bgcolor ? bgcolor : theme.palette.white,
    });

    return (
        <Stack className={containerStyles}>
            <Text styles={numberStyles}><CountUp end={number} duration={5} /></Text>
            <Text variant='large'>{text}</Text>
            <Text variant='small'>{subtitle}</Text>
        </Stack>
    );
};

export default NumberDisplay;
