import { useAuth0 } from "@auth0/auth0-react";
import { DefaultButton } from "@fluentui/react";

export const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <DefaultButton
      onClick={() => logout()}
      text="Log Out"
      style={{ marginTop: "auto", marginBottom: "auto", marginRight: "4px" }}
    />
  );
};

