import { useEffect, useState } from "react";
import {
  DefaultPalette,
  IDropdownOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  sizeToPixels,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  yupToFormErrors,
  validateYupSchema,
} from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { IReportEditProps } from "./IReportEditProps";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";

import useApi from "../../hooks/useApi";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { ISite } from "../Customer/CustomerContentEditPanel";
import { IReport, IReportPost } from "./ReportContentEditPanel";
import { IReportTemplate } from "../../models/IReportTemplate";
import { FormikDropdown } from "../formik-fluent-ui-react/FormikDropdown";
import { Accordion } from "../General/Accordion";

const schemaReport = Yup.object().shape({
  name: Yup.string().required("You must enter a report name"),
  from_template_id: Yup.string(),
});

interface IReportFormValues {
  name: string;
  from_template_id: string;
}

export const ReportEditPanel = (props: IReportEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [report, setReport] = useState<IReport>();
  const [, setGlobalMessage] = useRecoilState(globalMessageState);
  const [templateSelectOptions, setTemplateSelectOptions] = useState<
    IDropdownOption[]
  >([]);

  const api = useApi();

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getReport();
  }, [props]);

  useEffect(() => {
    getReportTemplates();
  }, []);

  const getReportTemplates = async () => {
    // Use the Customer ID from properties
    try {
      const result = await api.get(`/templates?select=template_id,name`);

      let oDropDownOptions: IDropdownOption[] = [];
      (result.data.data as IReportTemplate[]).map((o) => {
        oDropDownOptions.push({
          key: o.template_id,
          text: o.name,
        });
      });

      setTemplateSelectOptions(oDropDownOptions);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Report ${props.report_id}`,
      });
    }
  };

  const getReport = async () => {
    // Use the Customer ID from properties
    try {
      const result = await api.get(`/reports/${props.report_id}`);

      setReport(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Report ${props.report_id}`,
      });
    }
  };

  if (!report && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  return (
    <Panel
      headerText={
        props.formMode === FormMode.Add
          ? "New Report"
          : "Edit Report Properties"
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      overlayProps={{ isDarkThemed: true }}
    >
      <Label>{report?.report_id}</Label>
      <Formik
        enableReinitialize
        initialValues={
          props.formMode === FormMode.Add
            ? {
                name: "",
                from_template_id:
                  templateSelectOptions.length > 0
                    ? (templateSelectOptions[0].key as string)
                    : "",
              }
            : {
                name: report ? report.name : "",
                from_template_id: report ? report.from_template_id : "",
              }
        }
        validationSchema={schemaReport}
        onSubmit={async (
          values: IReportFormValues,
          { setSubmitting }: FormikHelpers<IReportFormValues>
        ) => {
          // values has the values
          // console.log(values);

          try {
            let jsonAdd: IReportPost = {
              name: values.name,
              from_template_id: values.from_template_id,
            };

            if (props.customer_id) {
              jsonAdd = { ...jsonAdd, customer_id: props.customer_id };
            }

            if (props.site_id) {
              jsonAdd = { ...jsonAdd, site_id: props.site_id };
            }

            let result =
              props.formMode === FormMode.Edit
                ? await api.put(`/reports/${props.report_id}`, jsonAdd)
                : await api.post(`/reports`, jsonAdd);

            setGlobalMessage({
              action: PanelActionType.Success,
              owner: PanelActionOwner.Report,
              message: "Report Create/Update Success",
            });

            props.successFunction();
          } catch (error) {
            console.log("Error", error);

            setGlobalMessage({
              action: PanelActionType.Error,
              owner: PanelActionOwner.Customer,
              message: `Error Creating/Updating Report ${report?.name}`,
            });
          }
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <Stack
              styles={{
                root: {
                  marginBottom: 12,
                },
              }}
            >
              <Label htmlFor="name">Report Name</Label>
              <Field
                name="name"
                required
                id="name"
                component={FormikTextField}
              />

              <Label htmlFor="from_template_id">Template</Label>
              <Field
                name="from_template_id"
                id="from_template_id"
                component={FormikDropdown}
                options={templateSelectOptions}
                disabled={props.formMode === FormMode.Edit}
              />
            </Stack>
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit" disabled={!isValid || !touched}>
                {`${props.formMode === FormMode.Edit ? "Save" : "Submit"}`}{" "}
              </PrimaryButton>
            </Stack>
          </Form>
        )}
      </Formik>

      {/*
      <Accordion header='Debug' color={DefaultPalette.red}>
        <pre>{JSON.stringify(report, null, 2)}</pre>
          </Accordion>*/}
    </Panel>
  );
};
