
import { atom } from "recoil";
import { IGlobalMessage } from "../interfaces/IGlobalMessage";


  export const globalMessageState = atom({
    key: 'globalMessage', // unique ID (with respect to other atoms/selectors)
    default: {  
    } as IGlobalMessage // default value (aka initial value)
  });

  