import { useEffect, useState } from "react";
import {
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { Formik, Form, Field, FormikHelpers, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { ICustomerEditProps } from "./ICustomerEditProps";
import { ICustomer } from "../../models/ICustomer";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";

import useApi from "../../hooks/useApi";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";


const schemaCustomer = Yup.object().shape({
  name: Yup.string().required("You must enter a customer name"),
  address1: Yup.string(),
  address2: Yup.string(),
  city: Yup.string().required("You must enter a town/city"),
  county: Yup.string(),
  country: Yup.string(),
  postcode: Yup.string().required("You must enter a postcode")
});

interface ICustomerFormValues {
  name: string;
  address1?: string;
  address2?: string;
  city: string;
  county?: string;
  country?: string;
  postcode: string;

}

export const CustomerEditPanel = (props: ICustomerEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const [,setGlobalMessage] = useRecoilState(globalMessageState)

  const api = useApi();

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getCustomer();
  }, [props]);

  const getCustomer = async () => {

    // Use the Customer ID from properties
    try {


      const result = await api.get(`/customers/${props.customer_id}`);

      setCustomer(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Customer ${props.customer_id}`
      });

    }
  };

  if (!customer && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  return (
    <Panel
      headerText={
        props.formMode === FormMode.Add ? "New Customer" : "Edit Customer"
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      overlayProps={{ isDarkThemed: true }}

    >
      <Label>{customer?.customer_id}</Label>
      <Formik
        enableReinitialize
        initialValues={
          props.formMode === FormMode.Add
            ? {
              name: "",
              city: "",
    
              postcode: ""

            }
            : {
              name: customer ? customer.name : "",
              address1: customer?.address1,
              address2: customer?.address2,
              city: customer? customer.city : "",
              county: customer?.county,
              country: customer?.country,
              postcode: customer ? customer.postcode : ""

            }
        }
        validationSchema={schemaCustomer}
 
        onSubmit={async (
          values: ICustomerFormValues,
          { setSubmitting }: FormikHelpers<ICustomerFormValues>
        ) => {
          // values has the values
          // console.log(values);


          try {
            let result =
              props.formMode === FormMode.Edit
                ? await api.put(`/customers/${props.customer_id}`, {
                  name: values.name,
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  county: values.county,
                  country: values.country,
                  postcode: values.postcode

                })
                : await api.post(`/customers`, {
                  name: values.name,
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  county: values.county,
                  country: values.country,
                  postcode: values.postcode

                });

                setGlobalMessage({
                  action: PanelActionType.Success,
                  owner: PanelActionOwner.Customer,
                  message: "Customer Create/Update Success"
                });

            props.successFunction();

          } catch (error) {
            console.log('Error', error)

            setGlobalMessage({
              action: PanelActionType.Error,
              owner: PanelActionOwner.Customer,
              message: `Error Creating/Updating Customer ${customer?.name}`
            });

          }
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <Stack styles={{
              root: {
                marginBottom: 12
              },
            }}>
              <Label htmlFor="name">Customer Name</Label>
              <Field name="name" id="name" component={FormikTextField} />
              
              <Label htmlFor="address1">Address Line 1</Label>
              <Field name="address1" id="address1" component={FormikTextField} />
              <Label htmlFor="address2">Address Line 2</Label>
              <Field name="address2" id="address2" component={FormikTextField} />
              <Label htmlFor="city">City</Label>
              <Field name="city" id="city" component={FormikTextField} />
              <Label htmlFor="county">County</Label>
              <Field name="county" id="county" component={FormikTextField} />
              <Label htmlFor="country">Country</Label>
              <Field name="country" id="country" component={FormikTextField} />
              <Label htmlFor="postcode">PostCode</Label>
              <Field name="postcode" id="postcode" component={FormikTextField} />
            </Stack>
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit" >Submit</PrimaryButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Panel>
  );
};


