import React, { useEffect, useState } from "react";

export function usePersistedState(key: string, defaultValue: any) {
	/*
	const [state, setState] = React.useState(
	  () => JSON.parse(localStorage.getItem(key)) || defaultValue
	);
	*/
	const [state, setState] = useState(() => {
		const storedValue = typeof window !== "undefined" ? sessionStorage.getItem(key) : null;
		return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
	});
	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
  }