import {
  DetailsList,
  Text,
  IconButton,
  Label,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TooltipHost,
  Breadcrumb,
  Separator,
  PrimaryButton,
  Spinner,
  ActionButton,
  Link,
  DatePicker,
  VerticalDivider,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { json } from "stream/consumers";
import { ISite } from "../components/Customer/CustomerContentEditPanel";
import { DataTable } from "../components/General/DataTable";
import PageTitle from "../components/layout/pageTitle";
import { ReportContentEditPanel } from "../components/Reports/ReportContentEditPanel";
import { SiteEditPanel } from "../components/Site/SiteEditPanel";
import { PanelActionType, PanelActionOwner, FormMode } from "../enums";
import useApi from "../hooks/useApi";
import { usePersistedState } from "../hooks/usePersistedState";
import { ICustomer } from "../models/ICustomer";
import { IReport } from "../models/IReport.ts";
import { useId } from "@fluentui/react-hooks";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../atoms/GlobalMessage";
import { ExportType } from "../enums/ExportType";
import { IExportResult } from "../interfaces/IExportResult";
import dayjs from "dayjs";
import { useTenantInfo } from "../hooks/useTenantInfo";

interface IReportListDisplayItem {
  report_id: string;
  report_name: string;
  site_name: string;
}

const Customer = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [isopen, setisopen] = useState(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const [customerSites, setCustomerSites] = useState<ISite[]>([]);
  const [totalCustomerSites, setTotalCustomerSites] = useState(0);
  const [currentSite, setCurrentSite] = useState<ISite>();

  const [isEditSiteOpen, setIsEditSiteOpen] = useState(false);
  const [isViewReportsOpen, setIsViewReportsOpen] = useState(false);

  // Paging
  const [pageSize, setPageSize] = usePersistedState("CustomerPageSize", 10);
  const [currentPage, setCurrentPage] = usePersistedState(
    "CustomerCurrentPage",
    1
  );

  // Searching
  const [searchTerm, setSearchTerm] = useState("");

  const detailTooltipId = useId("detailTooltip");

  const [exportLink, setExportLink] = useState<IExportResult>();

  const [waitingCSV, setWaitingCSV] = useState<boolean>(false);

  const [reportFromDate, setReportFromDate] = useState<Date>(new Date());

  const tenantInfo = useTenantInfo();

  const api = useApi();
  const [, setGlobalMessage] = useRecoilState(globalMessageState);

  const getCustomer = async () => {
    // Use the Customer ID from properties
    try {
      const result = await api.get(`/customers/${id}`);

      setCustomer(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Customer ${id}`,
      });
    }
  };

  const getCustomerSites = async () => {
    // Use the Customer ID from properties
    try {
      const result = await api.get(
        `/sites?customerId=${id}&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`
      );

      setCustomerSites(result.data.data);

      setTotalCustomerSites(result.data.count);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Customer Sites ${id}`,
      });
    }
  };

  const getCustomerReportCSV = async (
    exportType: ExportType,
    dateFrom: Date
  ) => {
    const exportTypeAsString: string = exportType.toString();

    const result = await api.post(`/export/reports`, {
      export_scope: "customer",
      customer_id: id,
      from_date: dayjs(dateFrom).format("YYYY-MM-DD"),
    });

    //console.log("Export", result.data);

    setExportLink({
      access_url: result.data.access_url,
      filename: result.data.filename,
      type: exportType,
    });
  };

  // Whenever the change, search or pagesize changes, get the data
  useEffect(() => {
    getCustomer();
    getCustomerSites();
  }, [currentPage, searchTerm, pageSize]);

  return (
    <>
      <Breadcrumb
        items={[
          {
            text: "Customers",
            key: "customers",
            onClick: () => navigate("/customers"),
          },
          {
            text: customer ? customer?.name : "",
            key: "customer",
            onClick: () => {},
          },
        ]}
        maxDisplayedItems={10}
        ariaLabel="Breadcrumb with items rendered as buttons"
        overflowAriaLabel="More links"
      />
      <Separator />
      <Stack tokens={{ childrenGap: 10 }}>
        <StackItem>
          {customer?.address1 && <Text>{customer?.address1}</Text>}
          {customer?.address2 && <Text> ,{customer?.address2}</Text>}
          {customer?.city && <Text> ,{customer?.city}</Text>}
          {customer?.county && <Text> ,{customer?.county}</Text>}
          {customer?.country && <Text> ,{customer?.country}</Text>}
          {customer?.postcode && <Text> ,{customer?.postcode}</Text>}
        </StackItem>

        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <StackItem grow={1}>
            <Stack  tokens={{ childrenGap: 10 }}>
              <Text variant="large">Sites</Text>

              <Text>Number of Sites: {totalCustomerSites}</Text>
            </Stack>
          </StackItem>
          
          {!tenantInfo?.roles.includes("customer_viewer") && <StackItem>
            <VerticalDivider />
          </StackItem>}
          {!tenantInfo?.roles.includes("customer_viewer") && (<StackItem>
          
            <Stack>
            {waitingCSV && <Spinner />}
            <DatePicker disabled={waitingCSV} label="Site Reports From Date" value={reportFromDate} onSelectDate={(date) => {
                setExportLink(undefined)
                setReportFromDate(date as Date)}}
                 />
            <PrimaryButton
             
              disabled={waitingCSV}
              iconProps={{ iconName: "ExcelLogo" }}
              onClick={async () => {
                setWaitingCSV(true);
                await getCustomerReportCSV(
                  ExportType.csv,
                  reportFromDate
                );
                setWaitingCSV(false);
              }}
            >
              Executive Summary Report (All Sites)
            </PrimaryButton>
            {exportLink && !waitingCSV && (
          <>
            <Text>Download Is Now Available: </Text>
            <Link target={"_blank"} href={exportLink.access_url}>
              Click here to download report
            </Link>
          </>
        )}
        </Stack>
            
          </StackItem>)}

        </Stack>

      

        <Separator />

        <DataTable
          newButtonText="New Customer Site"
          pageSize={pageSize}
          columns={[
            {
              key: "name",
              name: "Site Name",
              fieldName: "name",
              minWidth: 100,
            },

            {
              key: "address1",
              name: "Address 1",
              fieldName: "address1",
              minWidth: 100,
            },
            {
              key: "postcode",
              name: "PostCode",
              fieldName: "postcode",
              minWidth: 100,
            },
            {
              key: "actions",
              name: "Actions",
              minWidth: 100,
              isResizable: false,
              onRender: (item) => (
                <Stack horizontal>
                  <TooltipHost
                    content="Edit Site"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={detailTooltipId}
                  >
                    <IconButton
                      aria-describedby={detailTooltipId}
                      iconProps={{ iconName: "Edit" }}
                      disabled={tenantInfo?.roles.includes("customer_viewer")}
                      onClick={() => {
                        setCurrentSite(item);
                        setIsEditSiteOpen(true);
                      }}
                    />
                  </TooltipHost>

                  <TooltipHost
                    content="View Site Reports"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={detailTooltipId + "-reports"}
                  >
                    <IconButton
                      aria-describedby={detailTooltipId + "-reports"}
                      iconProps={{ iconName: "View" }}
                      onClick={() => {
                        navigate(
                          `/reports/${customer?.customer_id}/${item.site_id}`
                        );
                      }}
                    />
                  </TooltipHost>
                </Stack>
              ),
            },
          ]}
          hideNewButton={tenantInfo?.roles.includes("customer_viewer")}
          items={customerSites}
          totalItems={totalCustomerSites}
          onNewItemClick={() => {
            setCurrentSite(undefined);
            setIsEditSiteOpen(true);
          }}
          onItemClicked={(item: ISite) => {
            //setCurrentCustomer(item); setOpenEditPanel(true)
            navigate(`/reports/${customer?.customer_id}/${item.site_id}`);
          }}
          currentPage={currentPage}
          onPaginationClick={(page: number) => {
            setCurrentPage(page);
          }}
          onSearchTextChange={(text: string) => {
            setCurrentPage(1);
            setSearchTerm(text);
          }}
          onPageSizeChange={(size: number) => {
            setPageSize(size);
          }}
        />
      </Stack>

      <SiteEditPanel
        formMode={currentSite ? FormMode.Edit : FormMode.Add}
        isOpen={isEditSiteOpen}
        site_id={currentSite ? currentSite.site_id : ""}
        dismissFunction={() => setIsEditSiteOpen(false)}
        successFunction={() => {
          setCurrentSite(undefined);
          setIsEditSiteOpen(false);
          getCustomerSites();
        }}
        customer_id={id as string}
      />
    </>
  );
};

export default Customer;
