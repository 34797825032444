import { IconButton, Stack, TooltipHost } from "@fluentui/react";
import { FC } from "react"
import { Interface } from "readline"

export interface IAddToolbarProps {

    id: string;
    label: string;
    
    onAddSectionClick?: any;
    onEditSectionClick?: any;
    onAddQuestionClick?: any;
    onEditQuestionClick?: any;
    onAddPageClick?: any;
    onEditPageClick?: any;
    onDeleteItemClick?: any;
    displayDeleteButton?: boolean;
    displayEditSectionButton?: boolean;
    displayAddSectionButton?: boolean;
    displayAddQuestionButton?: boolean;
    displayEditQuestionButton?: boolean;
    displayAddPageButton?: boolean;
    displayEditPageButton?: boolean;
}
export const AddToolbar: FC<(IAddToolbarProps)> = (props) => {

    return (
        <Stack horizontal>

                  {/* Add */}
            
            {props.displayAddSectionButton && <TooltipHost
                content={`Add Section below '${props.label}'`}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={`addsection-${props.id}`}

            >
                <IconButton onClick={props.onAddSectionClick} aria-describedby={`addsection-${props.id}`} iconProps={{ iconName: 'DependencyAdd' }}></IconButton>
            </TooltipHost>}

            {props.displayAddQuestionButton && <TooltipHost
                content={`Add Question below '${props.label}'`}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={`addquestion-${props.id}`}

            >
                <IconButton onClick={props.onAddQuestionClick} aria-describedby={`addquestion-${props.id}`} iconProps={{ iconName: 'AddTo' }} ></IconButton>
            </TooltipHost>}

            {props.displayAddPageButton && <TooltipHost
                content={`Add Page below '${props.label}'`}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={`addpage-${props.id}`}

            >
                <IconButton onClick={props.onAddPageClick} aria-describedby={`addpage-${props.id}`} iconProps={{ iconName: 'PageAdd' }} ></IconButton>
            </TooltipHost>}

        
            {/* Edit */}


            {props.displayEditSectionButton && 
                <TooltipHost
                    content={`Edit Section '${props.label}'`}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={`editsection-${props.id}`}

                >
                    <IconButton onClick={props.onEditSectionClick} aria-describedby={`editsection-${props.id}`} iconProps={{ iconName: 'edit' }}></IconButton>
                </TooltipHost>
            }

            {props.displayEditQuestionButton &&
                <TooltipHost
                    content={`Edit Question '${props.label}'`}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={`editquestion-${props.id}`}

                >
                    <IconButton onClick={props.onEditQuestionClick} aria-describedby={`editquestion-${props.id}`} iconProps={{ iconName: 'edit' }}></IconButton>
                </TooltipHost>
            }

            {props.displayEditPageButton &&
                <TooltipHost
                    content={`Edit Page '${props.label}'`}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={`editpage-${props.id}`}

                >
                    <IconButton onClick={props.onEditPageClick} aria-describedby={`editpage-${props.id}`} iconProps={{ iconName: 'edit' }}></IconButton>
                </TooltipHost>
            }

            {/* DELETE */}

            {!props.displayDeleteButton && <TooltipHost
                content={`Delete '${props.label}'`}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={`delete-${props.id}`}

            >
                <IconButton onClick={props.onDeleteItemClick} aria-describedby={`delete-${props.id}`} iconProps={{ iconName: 'trash' }} ></IconButton>
            </TooltipHost>}


        </Stack>

    )
}