import Products from "../components/Configuration/Products"
import SelectLists from "../components/Configuration/SelectLists"
import PageTitle from "../components/layout/pageTitle"


const ProductsListEditor = () => {


    return <> <PageTitle title="Products" /><Products /></>
}

export default ProductsListEditor