import { Text, Separator, TooltipHost, IconButton, Label, ActionButton, Stack, DetailsList, SelectionMode, IDropdownOption, DefaultPalette, StackItem, PrimaryButton } from "@fluentui/react";
import { Field } from "formik";
import { FC, ReactElement, useState } from "react"
import { useRecoilState } from "recoil";
import { currentDocumentItem, currentMediaItem } from "../../atoms/CustomerAtom";
import { FIELDTYPE_TEXT, FIELDTYPE_NUMBER, FIELDTYPE_DATETIME, FIELDTYPE_BOOLEAN, FIELDTYPE_SELECT, FIELDTYPE_MEDIA } from "../../constants/fieldTypes";
import { ITEMTYPE_FIELD, ITEMTYPE_LOGIC_FIELD, ITEMTYPE_PAGE, ITEMTYPE_SECTION } from "../../constants/itemTypes";
import { IDocument } from "../../interfaces/IDocument";
import { IItem } from "../../models/IItem";
import { FormikDatePicker } from "../formik-fluent-ui-react/FormikDatePicker";
import { FormikDropdown } from "../formik-fluent-ui-react/FormikDropdown";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";
import { FormikToggle } from "../formik-fluent-ui-react/FormikToggle";
import { ReportRenderer, GetFieldValue } from "../Reports/ReportRenderer";
import { Accordion } from "./Accordion";
import { useId } from "@fluentui/react-hooks";
import { globalSelectListsState } from "../../atoms/SelectListsState";
import { FormikMediaField } from "../formik-fluent-ui-react/FormikMedia";

export interface IRevizioFieldProps {
    items: IItem[];
    updateFunction: void;
    shadowSetFunction: any;
    shadowGetFunction: any;
    initialValues: any;
    validateForm: any;
    templateItems: IItem[];
    addNewSectionFunction: any;
    addNewActionFunction: any;
    parentItem: any;
    setFieldValue: any;
    disabled: boolean;
}

export function getSelectItems(selectLists: any[], list_id: string): IDropdownOption[] {
    let oDropDownOptions: IDropdownOption[] = [];

    //console.log('List ID',list_id)
    //console.log('Items',selectLists)

    const oSelectListItem = selectLists.filter(o => {
        return o.list_id === list_id;
    })[0];

    oSelectListItem.items.map((o: any) => {
        oDropDownOptions.push({
            key: o.value,
            text: o.display
        })
    })

    return oDropDownOptions.sort((a, b) => {
        return a.text.localeCompare(b.text)
    });
}


export const RevizioField: FC<(IRevizioFieldProps)> = (props) => {

    const [mediaItem, setMediaItem] = useRecoilState(currentMediaItem)
    const [document, setDocument] = useRecoilState(currentDocumentItem)
    const detailTooltipId = useId('detailTooltip');

    const [selectLists, setSelectLists] = useRecoilState(globalSelectListsState)






    let element: any = <></>


    let controls: ReactElement[] = [];

    props.items.map((o: IItem, index) => {


        switch (o.item_type) {

            case ITEMTYPE_SECTION: {
                element = (<Accordion header={o.label} actions=
                    {

                        <TooltipHost
                            content={'Edit Section Name'}
                            // This id is used on the tooltip itself, not the host
                            // (so an element with this id only exists when the tooltip is shown)
                            id={`editsection-${o.item_id}`}

                        >
                            <IconButton aria-describedby={`editsection-${o.item_id}`} iconProps={{ iconName: 'Edit' }}></IconButton>
                        </TooltipHost>}
                    key={index} isExpanded={false}>
                    <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />
                    {o.from_item_template_id && props.templateItems.filter(f => {
                        return (f.parent_item_id === o.from_item_template_id && f.item_type === ITEMTYPE_SECTION && f.is_repeatable_section === true)
                    }).map((item, index) => {
                        return <Separator key={index}>

                            <TooltipHost
                                content={`Add repeatable Section '${item.label}'`}
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                id={`addsubsection`}

                            >
                                <IconButton aria-describedby="addsubsection" iconProps={{ iconName: "DependencyAdd" }} onClick={() => { item.item_id && item.parent_item_id && props.addNewSectionFunction(item.item_id, o.item_id, item.label) }} />
                            </TooltipHost>

                        </Separator>
                    })}
                </Accordion>)
                break;
            }
            case ITEMTYPE_LOGIC_FIELD: {

                element = <Stack key={index} >

                    {o.parent_item_id && <GetFieldValue logicItem={o} renderer={
                        <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />
                    } />}

                </Stack>;
                break;
            }
            case ITEMTYPE_FIELD: {


                switch (o.field_type) {
                    case FIELDTYPE_TEXT: {
                        element = <Stack key={index} >
                            <Label htmlFor={o.item_id}>{o.label}</Label>
                            <Field disabled={props.disabled}
                                name={o.item_id} id={o.item_id} multiline={o.field_properties?.text_is_multiline} rows={5} component={FormikTextField} />
                            <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />

                        </Stack>;
                        break;

                    }
                    case FIELDTYPE_NUMBER: {
                        element = <div key={index} >
                            <Label htmlFor={o.item_id}>{o.label}</Label>
                            <Field disabled={props.disabled}
                                name={o.item_id} id={o.item_id} component={FormikTextField} />
                            <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />

                        </div>;
                        break;

                    }
                    case FIELDTYPE_DATETIME: {
                        element = <Stack key={index} >
                            <Label htmlFor={o.item_id}>{o.label}</Label>
                            <Field disabled={props.disabled} name={o.item_id} id={o.item_id} component={FormikDatePicker} />
                            <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />

                        </Stack>;
                        break;
                    }
                    case FIELDTYPE_BOOLEAN: {
                        element = <Stack key={index} >
                            <Label htmlFor={o.item_id}>{o.label}</Label>
                            <Field disabled={props.disabled} name={o.item_id} id={o.item_id} component={FormikToggle} />
                            <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />



                        </Stack>;
                        break;
                    }
                    case FIELDTYPE_SELECT: {


                        element = <Stack key={index} >
                            <Label htmlFor={o.item_id}>{o.label}</Label>
                            <Field disabled={props.disabled} name={o.item_id} id={o.item_id} component={FormikDropdown} options={o.field_properties?.select_list_id && getSelectItems(selectLists, o.field_properties?.select_list_id
                            )}

                            />
                            <ReportRenderer disabled={props.disabled} parentItem={o} initialValues={props.initialValues} isTopLevel={false} items={o.items} updateFunction={props.updateFunction} shadowSetValueFunction={props.shadowSetFunction} shadowGetValueFunction={props.shadowGetFunction} validateForm={props.validateForm} templateItems={props.templateItems} addNewSectionFunction={props.addNewSectionFunction} addNewActionFunction={props.addNewActionFunction} setFieldValueFunction={props.setFieldValue} />

                        </Stack>;
                        break;
                    }
                    case FIELDTYPE_MEDIA: {

                        element = (<Stack styles={{
                            root: {
                                background: DefaultPalette.neutralLighter,
                                padding: 5,
                                marginTop: 10,
                                cursor: "pointer",
                                borderLeftStyle: "solid",
                                borderLeftColor: DefaultPalette.themePrimary,
                                borderLeftWidth: 3,

                            }
                        }} key={index}>
                            <Label htmlFor={o.item_id}>{o.label}</Label>
                            <Field disabled={props.disabled} id={o.item_id} name={o.item_id} component={FormikMediaField} />
                        </Stack>);

                        break;
                    }

                    default: {
                        element = <span key={index} >{o.field_type}</span>
                    }
                }

            }
        }

        // Add an action option if it is a field (i.e not Section)
        o.item_type === ITEMTYPE_FIELD ? controls.push(<Stack key={index} horizontal><StackItem>
            <TooltipHost
                content={`Manage Action for '${o.label}'`}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={`action-${o.item_id}`}

            >
                <IconButton disabled={props.disabled} aria-describedby={`action-${o.item_id}`} iconProps={{
                    iconName: 'SetAction'
                    
                }} styles={ o.actions && o.actions.length > 0 ? { root: { color: 'red'}} : {}} onClick={() => props.addNewActionFunction(o)} />
            </TooltipHost>
        </StackItem><StackItem>{element}</StackItem></Stack>) : controls.push(element)
    })



    return (<>{controls}</>);


}


