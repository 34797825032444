
import { IDropdownOption } from "@fluentui/react";
import { atom } from "recoil";
import { IProduct } from "../interfaces/IProduct";



  export const globalProductsState = atom({
    key: 'productsState', // unique ID (with respect to other atoms/selectors)
    default:[] as IProduct [] // default value (aka initial value)
  });
