import { useAuth0 } from "@auth0/auth0-react";
import { Separator, Stack, Text, Image } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { ITenantInfo } from "../../interfaces/ITenantInfo";
import { pageHeaderStyles } from "../../styles";
import { LoginButton } from "../auth0/LoginButton";
import { LogoutButton } from "../auth0/LogoutButton";
import { ProfileViewPanel } from "../Profile/ProfileViewPanel";

const PageHeader = () => {
  const { isAuthenticated } = useAuth0();

  const tenantInfo = useTenantInfo();

  return (
    <Stack
      horizontal
      styles={pageHeaderStyles}
      verticalAlign="center"
      horizontalAlign="space-between"
    >
      <Image src="/assets/reviziologowebsmall.png" alt="Revizio Logo" width={150} />
      {isAuthenticated && <Stack verticalAlign="center" horizontalAlign="center">
        
          <Text variant="xLargePlus">{tenantInfo?.name}</Text>
       

        <Text variant="small">(Version 2024-02-22:01)</Text>
      </Stack> }
      {!isAuthenticated && (
        <>
          <LoginButton />
        </>
      )}
      {isAuthenticated && <ProfileViewPanel />}
    </Stack>
  );
};

export default PageHeader;
