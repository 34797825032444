export enum FormMode {
	Add,
	Edit
}

export enum PanelActionType {
	Dismiss,
	Success,
	Error
}

export enum PanelActionOwner {
	Customer,
	Site,
	ReportTemplate,
	ReportTemplateItem,
	Report
}

