import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { ITenantInfo } from "../interfaces/ITenantInfo";
import useApi from "./useApi";
import { jwtDecode } from "jwt-decode";



export const useTenantInfo = () => {

    const [tenantInfo, setTenantInfo] = useState<ITenantInfo>();

    const [loading, setLoading] = useState(true);

    const { getAccessTokenSilently } = useAuth0();


    const api = useApi();

    const fetchTenantInfo = async () => {

        setLoading(true);

        try {
            const result = await api.get('/tenantinfo');

            const token = await getAccessTokenSilently();

            const decoded:any = jwtDecode(token);


            //const role = decoded['https://revizio-api/auth_info']?.roles[0] || 'user';
            const roles: string [] = decoded['https://revizio-api/auth_info']?.roles || ['user'];
            if(roles.length === 0) roles.push('user');
            const tenantId = decoded['https://revizio-api/auth_info']?.tenant_id || '';
            const customerId = decoded['https://revizio-api/auth_info']?.customer_id || '';

            setTenantInfo({
                name: result.data.name,
                _id: result.data._id,
                roles: roles,
                tenantId: tenantId,
                customerId: customerId
            });
        } catch (error) {
            console.error(error)
        }
        setLoading(false);


    };

    useEffect(() => {


        fetchTenantInfo();


    }, []);

    return tenantInfo;
}