import { ISelectList, ISelectListItem } from "../../models/ISelectList";
import useApi from "../../hooks/useApi";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { result } from "lodash";
import { PanelActionType, PanelActionOwner, FormMode } from "../../enums";
import { DetailsRow, Dropdown, getTheme, IconButton, IDetailsListProps, IDetailsRowStyles, Label, Stack, StackItem, Text, TooltipHost } from "@fluentui/react";
import { DataTable } from "../General/DataTable";
import { usePersistedState } from "../../hooks/usePersistedState";
import customer from "../../pages/customer";
import { ISite } from "../Customer/CustomerContentEditPanel";
import { useId } from "@fluentui/react-hooks";
import useStateIfMounted from "../../hooks/useStateIfMounted";
import { SelectListEditPanel } from "./SelectListEditPanel";


const SelectLists = () => {

    const api = useApi();

    const [selectLists, setSelectLists] = useState<ISelectList[]>([])
    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    // Paging
    const [pageSize, setPageSize] = usePersistedState('SelectListsPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('SelectListsCurrentPage', 1);

    // Searching
    const [searchTerm, setSearchTerm] = useState("");

    const [currentSelectList, setCurrentSelectList] = useState<ISelectList>();

    const [totalSelectLists, setTotalSelectLists] = useStateIfMounted<number>(0);

    const detailTooltipId = useId('detailTooltip');


    const [isEditSiteOpen, setIsEditSiteOpen] = useState(false);


    const getPagedSelectLists = async () => {

     

        try {


            const result = await api.get(`/configuration/lists?orderBy=name&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`);

            console.log('Select Lists',result.data.data)

            setSelectLists(result.data.data);
            setTotalSelectLists(result.data.count)



        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Select Lists`
            });

        }

    }


    useEffect(() => {

        getPagedSelectLists();

    }, [currentPage, searchTerm, pageSize])



    return (<><DataTable
    newButtonText="New Select List"
        pageSize={pageSize}

        columns={
            [

                {
                    key: 'name',
                    name: 'Select List Name',
                    fieldName: 'name',
                    minWidth: 100,
                    maxWidth: 400
                },

                {
                    key: 'items',
                    name: 'Items',
                    fieldName: 'items',
                    minWidth: 100,
                    maxWidth: 400,
                    onRender: (item: ISelectList) => (
                        <Stack tokens={{
                            childrenGap: 5
                        }} horizontal wrap>{item.items.sort((a, b) => {
                            return a.display.localeCompare(b.display)
                        }).map((o: ISelectListItem, index) => {
                            return <StackItem key={index}>{o.display}</StackItem>

                        })}
                        </Stack>)
                },
                {

                    key: 'actions',
                    name: 'Actions',
                    minWidth: 100,
                    isResizable: false,
                    onRender: (item) => (
                        <Stack horizontal>




                            <TooltipHost
                                content="Edit Select List"
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                id={detailTooltipId}
                            >

                                <IconButton aria-describedby={detailTooltipId} iconProps={{ iconName: 'Edit' }} onClick={() => {  setIsEditSiteOpen(true); setCurrentSelectList(item) }
                                } />
                            </TooltipHost>




                        </Stack>
                    ),
                },
            ]
        }
        items={selectLists}
        totalItems={totalSelectLists}
        onNewItemClick={() => {
            setIsEditSiteOpen(true)
        }}
        onItemClicked={(item: ISite) => {

        }}
        currentPage={currentPage}
        onPaginationClick={(page: number) => {
            setCurrentPage(page);
        }}
        onSearchTextChange={(text: string) => {
            setCurrentPage(1);
            setSearchTerm(text);
        }}
        onPageSizeChange={(size: number) => {
            setPageSize(size);
        }} />
        <SelectListEditPanel formMode={currentSelectList ? FormMode.Edit : FormMode.Add} isOpen={isEditSiteOpen} list_id={currentSelectList ? currentSelectList.list_id : ""} dismissFunction={() => setIsEditSiteOpen(false)} successFunction={() => {
            setCurrentSelectList(undefined);
            setIsEditSiteOpen(false);
            getPagedSelectLists();
        }} /></>)

   
}

        export default SelectLists;