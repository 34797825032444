import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

import React from "react";

export const Auth0ProviderWithHistory: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || 'NO VAR';

  const clientId:string = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const audience:string = process.env.REACT_APP_AUTH0_AUDIENCE || '';

  const onRedirectCallback = (appState:any) => {
    navigate(appState && appState.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="openid profile email"
    >
      {children}
    </Auth0Provider>
  );
};



