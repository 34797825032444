import { useRef, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';


// Auth0 hook for Axious which injects bearer token and keeps it alive
export default () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  
  const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`
    })
    
  );

  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptorId = currentAPI.interceptors.request.use(async config => {
      const token = await getAccessTokenSilently();
      if (config.headers) {
        config.headers.authorization = `Bearer ${token}`;
      }  
      config.cancelToken = axios.CancelToken.source().token;
      return config;
    });
    const responseInterceptorId = currentAPI.interceptors.response.use(undefined, async error => {
      if (error.config && error.response && error.response.status === 401) {
        await loginWithRedirect({
          redirect_uri: window.location.origin
        });
      }

      return Promise.reject(error);
    });
    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
      currentAPI.interceptors.response.eject(responseInterceptorId);
    };
  });
  return api.current;
};