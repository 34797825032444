import { CommandBar, Dropdown, DetailsList, IColumn, ICommandBarItemProps, Stack, StackItem, Text, SelectionMode, DetailsRow, getTheme, IDetailsListProps, IDetailsRowStyles } from "@fluentui/react"
import { FC, useState } from "react"
import Pagination from "./Pagination";
import { Search } from "./Search";

export interface IDataTableProps {
    pageSize: number,
    columns: IColumn[],
    items: any[],
    totalItems: number,
    onNewItemClick(): any,
    onItemClicked(item:any): any,
    currentPage: number
    onPaginationClick(page: number): any,
    onSearchTextChange(text: string): any,
    onPageSizeChange(size: number): any
    hideNewButton?: boolean
    newButtonText: string

}

export const DataTable: FC<(IDataTableProps)> = (props) => {


    const _commandBarItems: ICommandBarItemProps[] = props.hideNewButton ? [] : [
        {
            key: 'newItem',
            text: props.newButtonText,
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            onClick: () => { props.onNewItemClick && props.onNewItemClick() }

        }
    ]

    const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
          if (props.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: getTheme().palette.themeLighterAlt };
          }
    
          return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
      };

    return <>
        <Stack horizontal>
            <StackItem grow>
                <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
                    <Text>Show</Text>
                    <Dropdown styles={{
                        root: {
                            width: 60
                        }
                    }} onChange={(event, item) => {
                        item && props.onPageSizeChange &&  props.onPageSizeChange(+item.key)
                    }} selectedKey={props.pageSize.toString()} options={[{
                        key: '5', text: '5', disabled: props.totalItems < 5
                    }, {
                        key: '10', text: '10', disabled: props.totalItems < 10
                    }, {
                        key: '25', text: '25', disabled: props.totalItems < 25
                    }, {
                        key: '50', text: '50', disabled: props.totalItems < 50
                    }]} />
                    <Text>entries</Text>
                </Stack>
            </StackItem>
            <StackItem>
                <Search onChange={(newValue: string) => props.onSearchTextChange && props.onSearchTextChange(newValue) } />
            </StackItem>
        </Stack>

        <CommandBar
            items={_commandBarItems}
        />

        <DetailsList styles={{
            root: {
                minHeight: props.pageSize * 60
            }
        }} items={props.items ? props.items : []} columns={
            props.columns ? props.columns : []
        } onItemInvoked={(item) => {
            props.onItemClicked && props.onItemClicked(item);
        }} selectionMode={SelectionMode.none} onRenderRow={_onRenderRow} />

        <Stack horizontal>
            <StackItem grow>
                <Text>Showing {(props.pageSize * (props.currentPage - 1)) + 1} to {(props.pageSize * (props.currentPage) < props.totalItems) ? props.pageSize * props.currentPage : props.totalItems} of {props.totalItems} entries</Text>

            </StackItem>
            <StackItem>
                <Pagination onPageChange={(page: number) => props.onPaginationClick && props.onPaginationClick(page)} totalCount={props.totalItems} currentPage={props.currentPage} pageSize={props.pageSize} />

            </StackItem>
        </Stack>

    </>
}