import { Separator, Text } from "@fluentui/react";
import { FC } from "react"


interface PageTitleProps {
    title: string;
  }
  
const PageTitle = (props:PageTitleProps) => {


    return (

        <>
            <Text variant="large">{props.title}</Text>

            <Separator />
        </>
    );
}

export default PageTitle;