import { useEffect, useMemo, useState } from "react";
import {
  ActionButton,
  ContextualMenu,
  DefaultButton,
  DefaultPalette,
  DetailsRow,
  Dialog,
  DialogFooter,
  DialogType,
  GroupedList,
  Icon,
  IconButton,
  IDropdownOption,
  IGroup,
  IIconStyles,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  SelectionMode,
  Separator,
  Spinner,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { Formik, Form, Field, FormikHelpers, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";
import { useAuth0 } from "@auth0/auth0-react";
import useApi from "../../hooks/useApi";
import { IReportTemplate } from "../../models/IReportTemplate";
import { ReportTemplateEditPanel } from "./ReportTemplateEditPanel";
import dayjs from 'dayjs';
import { ReportTemplateItems } from "./ReportTemplateItem/ReportTemplateItems";
import { ReportTemplateItemEditPanel } from "./ReportTemplateItem/ReportTemplateItemEditPanel";
import { Accordion } from "../General/Accordion";
import { IItem } from "../../models/IItem";
import { AddToolbar } from "../General/AddToolbar";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { useBoolean } from '@fluentui/react-hooks';
import { ITEMTYPE_FIELD, ITEMTYPE_PAGE, ITEMTYPE_SECTION } from "../../constants/itemTypes";
import { ISelectList } from "../../models/ISelectList";
import { globalSelectListsState } from "../../atoms/SelectListsState";
import { IReport } from "../Reports/ReportContentEditPanel";
import { ReportFiller } from "../Reports/ReportFiller";
import { globalProductsState } from "../../atoms/ProductsState";


export interface IReportTemplateEditProps {
  template_id: string;
  isOpen: boolean;
  dismissFunction: any;
  successFunction: any;
  formMode: FormMode;
}

const iconStyles: IIconStyles = {
  root: {
    fontSize: '24px',
    height: '24px',
    width: '24px',
  },
};

export const ReportTemplateContentEditPanel = (props: IReportTemplateEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [template, setTemplate] = useState<IReportTemplate>();
  const [currentTemplateitem, setCurrentTemplateItem] = useState<any>();
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [openEditTemplateItemPanel, setOpenEditTemplateItemPanel] = useState(false);
  const [currentTemplateItemFormMode, setCurrentTemplateItemFormMode] = useState<FormMode>(FormMode.Add);
  const [currentTemplateItemItemType, setCurrentTemplateItemItemType] = useState<string>(ITEMTYPE_SECTION)
  const [selectLists,setSelectLists] = useRecoilState(globalSelectListsState)
  const [previewReport, setPreviewReport] = useState<IReport>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [products, setProducts] = useRecoilState(globalProductsState)


  const api = useApi();
  const [, setGlobalMessage] = useRecoilState(globalMessageState)

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getReportTemplate();
  }, [props]);

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const modalPropsStyles = { main: { maxWidth: 450 } };

  const modalProps = useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable],
  );

  const deleteTemplateItem = async (i: IItem) => {



    // Lets delete the item
    // Use the Customer ID from properties
    try {


      const result = await api.delete(`/templates/${props.template_id}/items/${i.item_id}`);

      getReportTemplate();
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Template ${props.template_id}`
      });

    }


  }

  const deletePreview = async () => {



    // Lets delete the item
    // Use the Customer ID from properties
    try {


      //console.log('Delete Report',previewReport?.report_id)
      const result = await api.delete(`/reports/${previewReport?.report_id}`);

      
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Report,
        message: `Error Deleting Report ${previewReport?.report_id}`
      });

    }


  }


  const getSelectLists = async () => {

    if(selectLists && selectLists.length > 0) {
      return
    }
    try {


      const result = await api.get(`/configuration/lists?noPaging=true`);
      setSelectLists(result.data.data);
      
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Select Lists`
      });

    }

  }

  const getProducts = async () => {

    if (products && products.length > 0) {
        return;
    }

    try {


        const result = await api.get(`/configuration/products?noPaging=true&orderBy=name`);
        setProducts(result.data);

    } catch (e) {
        console.error(e);

        setGlobalMessage({
            action: PanelActionType.Error,
            owner: PanelActionOwner.Report,
            message: `Error Getting Products`
        });

    }

}

useEffect(() => {

    getSelectLists();

    getProducts();

}, [])
  const getReportTemplate = async () => {

    // Use the Customer ID from properties
    try {


      const result = await api.get(`/templates/${props.template_id}`);

      setTemplate(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Template ${props.template_id}`
      });

    }
  };

  if (!template && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  const getPreviewReport = async () => {

    // Use the Customer ID from properties
    try {


        const result = await api.post(`/templates/${props.template_id}/preview`);

        setPreviewReport(result.data);

        setPreviewOpen(true)

       





    } catch (e) {
        console.error(e);

        setGlobalMessage({
            action: PanelActionType.Error,
            owner: PanelActionOwner.ReportTemplate,
            message: `Error Getting Report ${props.template_id}`
        });

    }
};

  return (
    <>
    <Panel
      headerText={
        props.formMode === FormMode.Add ? "New Report Template" : `Edit '${template?.name}' Template`
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.smallFluid}
      overlayProps={{ isDarkThemed: true }}


    >
      <Stack horizontal>
        <TooltipHost content="Edit the template details" id="editTemplateName">
          <ActionButton label="Edit Template Details" aria-describedby="editTemplateName" onClick={() => { setOpenEditPanel(true) }} iconProps={{ 'iconName': 'Edit' }}>Edit Template Details</ActionButton>
        </TooltipHost>
        <TooltipHost content="Preview Report" id="previewReport">
          <ActionButton label="Preview Report" aria-describedby="previewReport" onClick={() => { getPreviewReport()  }} iconProps={{ 'iconName': 'View' }}>Preview Report</ActionButton>
        </TooltipHost>
        </Stack>
      <Stack>

        <Text>{`Created: ${template?.created_at ? dayjs.unix(template.created_at).format('DD/MM/YYYY h:mm a') : ''}`}</Text>
        {template?.updated_at && <Text>{`Last Updated: ${template?.updated_at ? dayjs.unix(template.updated_at).format('DD/MM/YYYY h:mm a') : ''}`}</Text>} 
      
        
      </Stack>


      {template && <ReportTemplateItems parentLabel={template.name} onEdit={(i: IItem) => { setCurrentTemplateItemItemType(i.item_type); setCurrentTemplateItemFormMode(FormMode.Edit); setCurrentTemplateItem(i); setOpenEditTemplateItemPanel(true) }} onDelete={(i: IItem) => {
        setCurrentTemplateItem(i);
        toggleHideDialog();
        //deleteTemplateItem(i);
        //getReportTemplate();
      }} onAdd={(i: IItem, r: string) => { setCurrentTemplateItemItemType(r); setCurrentTemplateItemFormMode(FormMode.Add); setCurrentTemplateItem(i); setOpenEditTemplateItemPanel(true) }} name={template.name} items={template.items ? template.items : []} toplevel={true} />}


      {template && <ReportTemplateEditPanel formMode={FormMode.Edit} isOpen={openEditPanel} template_id={template.template_id} dismissFunction={() => { setOpenEditPanel(false); }} successFunction={(item: IReportTemplate) => {

        setOpenEditPanel(false);

        getReportTemplate();

      }} />}

      <Separator>

        <TooltipHost
          content={`Add Page`}
          // This id is used on the tooltip itself, not the host
          // (so an element with this id only exists when the tooltip is shown)
          id={`addpage`}

        >
          <IconButton aria-describedby="addpage" iconProps={{ iconName: "PageAdd" }} onClick={() => { setCurrentTemplateItemFormMode(FormMode.Add); setCurrentTemplateItemItemType(ITEMTYPE_PAGE); setCurrentTemplateItem(null); setOpenEditTemplateItemPanel(true) }} />
        </TooltipHost>
       
      </Separator>

  {/*
      <Accordion header={'(Debug)'} color={DefaultPalette.red}>
        <Text>{`Template ID: ${template?.template_id}`}</Text>
        <pre>{JSON.stringify(template, null, 2)}</pre>
      </Accordion>
    
      */}

      {template && <ReportTemplateItemEditPanel itemType={currentTemplateItemItemType} templateItem={currentTemplateitem} formMode={currentTemplateItemFormMode} isOpen={openEditTemplateItemPanel} templateId={template.template_id} parentTemplateItem={currentTemplateitem} dismissFunction={() => setOpenEditTemplateItemPanel(false)} successFunction={() => {

        setOpenEditTemplateItemPanel(false);
        getReportTemplate();

      }} />}

      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={{
          type: DialogType.normal,

          title: `Delete?`,
          subText: `Are you sure you want to delete '${currentTemplateitem?.label}'?`,
        }}
        modalProps={modalProps}
      >
        <DialogFooter>
          <TooltipHost content={`This delete cannot be undone`}
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            id={`deleteconfirm`}>
            <PrimaryButton aria-describedby="deleteconfirm" onClick={() => { deleteTemplateItem(currentTemplateitem); getReportTemplate(); toggleHideDialog() }} text="Delete" />
          </TooltipHost>
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>

     
      {previewReport && <ReportFiller disabled={false} report_id={previewReport?.report_id} isOpen={previewOpen} dismissFunction={() => {setPreviewOpen(false); deletePreview()}} successFunction={() => {
                setPreviewOpen(false); deletePreview();
            } }  />}

    </Panel>
   
            </>
  );
};


