import { useEffect, useRef, useState } from "react";

export default function useStateIfMounted<T>(
    initialState: T
): [T, (newState: T) => void] {
    const isMounted = useRef(true);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const setStateIfMounted = (newState: any) => {
        if (isMounted.current) {
            setState(newState);
        }
    };

    return [state, setStateIfMounted];
}