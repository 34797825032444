import {
  Breadcrumb,
  IconButton,
  Separator,
  Stack,
  TooltipHost,
  getTheme,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { ICustomer } from "../models/ICustomer";
import { CustomerEditPanel } from "../components/Customer/CustomerEditPanel";
import { FormMode } from "../enums";
import useApi from "../hooks/useApi";
import PageTitle from "../components/layout/pageTitle";
import useStateIfMounted from "../hooks/useStateIfMounted";
import { DataTable } from "../components/General/DataTable";
import { usePersistedState } from "../hooks/usePersistedState";
import { useId } from "@fluentui/react-hooks";
import { useRecoilState } from "recoil";
import { customerState } from "../atoms/CustomerAtom";
import { CustomerContentEditPanel } from "../components/Customer/CustomerContentEditPanel";
import { Navigate, useNavigate } from "react-router-dom";
import { useTenantInfo } from "../hooks/useTenantInfo";

const Customers = () => {
  const navigate = useNavigate();

  // Customer State
  //const [customers, setCustomers] = useStateIfMounted<ICustomer[]>([]);
  const [customers, setCustomers] = useRecoilState(customerState);
  const [totalcustomers, setTotalCustomers] = useStateIfMounted<number>(0);
  const [currentCustomer, setCurrentCustomer] = useState<ICustomer>();

  const tenantInfo = useTenantInfo();

  const theme = getTheme();

  // Panel Status
  const [openEditPanel, setOpenEditPanel] = useStateIfMounted<boolean>(false);
  const [openCustomerContentPanel, setOpenCustomerContentPanel] =
    useStateIfMounted<boolean>(false);

  // Paging
  const [pageSize, setPageSize] = usePersistedState("CustomerPageSize", 10);
  const [currentPage, setCurrentPage] = usePersistedState(
    "CustomerCurrentPage",
    1
  );

  // Searching
  const [searchTerm, setSearchTerm] = useState("");

  const detailTooltipId = useId("detailTooltip");

  // API Wrapper for axios with auto token
  const api = useApi();

  // Get the customers
  const fetchCustomers = async () => {
    try {
      const result = await api.get(
        `/customers?select=customer_id,name,address1,postcode&orderBy=name&sort=asc&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`
      );

      setCustomers(result.data.data);
      setTotalCustomers(result.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  // Whenever the change, search or pagesize changes, get the data
  useEffect(() => {
    fetchCustomers();
  }, [currentPage, searchTerm, pageSize]);

  return (
    <>
      <Stack>
        <Breadcrumb
    
          items={[
            {
              text: "Home",
              key: "home",
              onClick: () => navigate("/"),
            },
          ]}
          maxDisplayedItems={10}
     
          ariaLabel="Breadcrumb with items rendered as buttons"
          overflowAriaLabel="More links"
          
        />
        <Separator />

        <PageTitle title="Customers" />

        <DataTable
          newButtonText="New Customer"
          hideNewButton={tenantInfo?.roles.includes("customer_viewer")}
          pageSize={pageSize}
          columns={[
            {
              key: "name",
              name: "Name",
              fieldName: "name",
              minWidth: 100,
            },
            {
              key: "address1",
              name: "Address 1",
              fieldName: "address1",
              minWidth: 100,
            },
            {
              key: "postcode",
              name: "PostCode",
              fieldName: "postcode",
              minWidth: 100,
            },
            {
              key: "actions",
              name: "Actions",
              minWidth: 100,
              isResizable: false,
              onRender: (item) => (
                <Stack horizontal>
                  <TooltipHost
                    content="View Customer"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={detailTooltipId + "-view"}
                  >
                    <IconButton
                      aria-describedby={detailTooltipId + "-view"}
                      iconProps={{ iconName: "View" }}
                      onClick={() => {
                        navigate(`/customer/${item.customer_id}`);
                      }}
                    />
                  </TooltipHost>

                  <TooltipHost
                    content="Edit Customer"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={detailTooltipId}
                  >
                    <IconButton
                      disabled={tenantInfo?.roles.includes("user") || tenantInfo?.roles.includes("customer_viewer")}
                      aria-describedby={detailTooltipId}
                      iconProps={{ iconName: "Edit" }}
                      onClick={() => {
                        setCurrentCustomer(item);
                        setOpenEditPanel(true);
                      }}
                    />
                  </TooltipHost>
                </Stack>
              ),
            },
          ]}
          items={customers}
          totalItems={totalcustomers}
          onNewItemClick={() => {
            setCurrentCustomer(undefined);
            setOpenEditPanel(true);
          }}
          onItemClicked={(item: ICustomer) => {
            //setCurrentCustomer(item); setOpenEditPanel(true)
            //setCurrentCustomer(item); setOpenCustomerContentPanel(true)
            navigate(`/customer/${item.customer_id}`);
          }}
          currentPage={currentPage}
          onPaginationClick={(page: number) => {
            setCurrentPage(page);
          }}
          onSearchTextChange={(text: string) => {
            setCurrentPage(1);
            setSearchTerm(text);
          }}
          onPageSizeChange={(size: number) => {
            setPageSize(size);
          }}
        />

        <CustomerEditPanel
          formMode={currentCustomer ? FormMode.Edit : FormMode.Add}
          isOpen={openEditPanel}
          customer_id={currentCustomer ? currentCustomer.customer_id : ""}
          dismissFunction={() => setOpenEditPanel(false)}
          successFunction={() => {
            setCurrentCustomer(undefined);
            setOpenEditPanel(false);
            fetchCustomers();
          }}
        />

        {currentCustomer && (
          <CustomerContentEditPanel
            customer={currentCustomer}
            isOpen={openCustomerContentPanel}
            dismissFunction={() => setOpenCustomerContentPanel(false)}
            successFunction={() => {
              setOpenCustomerContentPanel(false);
              fetchCustomers();
            }}
            formMode={FormMode.Edit}
          />
        )}
      </Stack>
    </>
  );
};

export default Customers;
