import { useEffect, useState } from "react";
import {
  DefaultButton,
  DefaultPalette,
  IconButton,
  IDropdownOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  sizeToPixels,
  Spinner,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { Formik, Form, Field, FormikHelpers, yupToFormErrors, validateYupSchema, FieldArray } from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";

import useApi from "../../hooks/useApi";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { Accordion } from "../General/Accordion";
import { ISelectList, ISelectListItem } from "../../models/ISelectList";

import { useId } from "@fluentui/react-hooks";
import { IProduct } from "../../interfaces/IProduct";

export interface IProductEditProps {


  product_id?: string;
  isOpen: boolean;
  dismissFunction: any;
  successFunction: any;
  formMode: FormMode

}


const schemaProduct = Yup.object().shape({
  name: Yup.string().required("You must enter a Product name"),
  code: Yup.string().required("You must enter a Product code"),
  unit_price: Yup.number().required("You must enter a Unit Price"),
  currency: Yup.string().required("You must enter Currency"),
})


interface IProductFormValues {
  name: string;
 code: string;
 unit_price: number;
 currency: string;
}

interface IProductPost {
    name: string;
    code: string;
    unit_price: number;
    currency: string;
}

export const ProductEditPanel = (props: IProductEditProps) => {
  const [isopen, setisopen] = useState(false);
  
  const [, setGlobalMessage] = useRecoilState(globalMessageState)
  const detailTooltipId = useId('detailTooltip');

  const [product,setProduct] = useState<IProduct>()


  const api = useApi();

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getProduct();
  }, [props]);



  const getProduct = async () => {

    // Use the Customer ID from properties
    try {


      const result = await api.get(`/configuration/products/${props.product_id}`);

      setProduct(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Select List ${props.product_id}`
      });

    }
  };

  if (!product && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  return (
    <Panel
      headerText={
        props.formMode === FormMode.Add ? "New Product" : "Edit Product"
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      overlayProps={{ isDarkThemed: true }}

    >
      <Label>{product?.product_id}</Label>
      <Formik
        enableReinitialize
        initialValues={
          props.formMode === FormMode.Add
            ? {
              name: "",
              code: "",
              unit_price: 0,
              currency: "GBP"



            }
            : {
              name: product ? product.name : "",
              code: product ? product.code : "",
              unit_price: product ? product.unit_price : 0,
              currency: product ? product.currency : "GBP"


            }
        }
        validationSchema={schemaProduct}
        onSubmit={async (
          values: IProductFormValues,
          { setSubmitting }: FormikHelpers<IProductFormValues>
        ) => {
          // values has the values
          // console.log(values);


          try {

            let jsonAdd: IProductPost = {
              name: values.name,
              code: values.code,
              unit_price: values.unit_price,
              currency: values.currency
            }


            let result =
              props.formMode === FormMode.Edit
                ? await api.put(`/configuration/products/${props.product_id}`, jsonAdd)
                : await api.post(`/configuration/products`, jsonAdd);

            setGlobalMessage({
              action: PanelActionType.Success,
              owner: PanelActionOwner.Report,
              message: "Product Create/Update Success"
            });

            props.successFunction();

          } catch (error) {
            console.log('Error', error)

            setGlobalMessage({
              action: PanelActionType.Error,
              owner: PanelActionOwner.Customer,
              message: `Error Creating/Updating Product ${product?.name}`
            });

          }
        }}
      >
        {({ errors, touched, isValid, values }) => (
          <Form>
            <Stack styles={{
              root: {
                marginBottom: 12
              },
            }}>
              <Label htmlFor="name">Product Name</Label>
              <Field name="name" id="name" component={FormikTextField} />
              <Label htmlFor="code">Product Code</Label>
              <Field name="code" id="code" component={FormikTextField} />
              <Label htmlFor="name"> in PENCE (e.g. £400 = 40000)</Label>
              <Field name="unit_price" id="unit_price" component={FormikTextField} />
              <Label htmlFor="currency">Product Currency</Label>
              <Field name="currency" id="currency" component={FormikTextField} />
              
              

            </Stack>
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit" styles={{ root: { marginRight: 8 } }} disabled={!isValid}>{`${props.formMode === FormMode.Edit ? 'Save' : 'Submit'}`} </PrimaryButton>
              <DefaultButton onClick={props.dismissFunction} text="Cancel" />
            </Stack>
          </Form>
        )}
      </Formik>

      {/*
      <Accordion header='Debug' color={DefaultPalette.red}>
        <pre>{JSON.stringify(product, null, 2)}</pre>
      </Accordion>
          */}
    </Panel>
  );
};


