import { IDropdownOption } from "@fluentui/react"

export const fieldTypeOptions: IDropdownOption[] = [
    {
      text: 'Text',
      key: 'text'
    },
    {
      text: 'Number',
      key: 'number'
    },
    {
      text: 'Boolean',
      key: 'boolean'
    },
    {
      text: 'DateTime',
      key: 'datetime'
    },
    {
      text: 'Choice',
      key: 'select'
    },
    {
      text: 'Media',
      key: 'media'
    }
  
  ]
  
  export const logicOutcomes: IDropdownOption[] = [
    {
      text: 'Show Questions',
      key: 'showChildItems'
    }
  ]
  
  export const logicOperators: IDropdownOption[] = [
    {
      text: 'Equal',
      key: 'equal'
    },
    {
      text: 'Not Equals',
      key: 'notEqual'
    },
    {
      text: 'Less Than',
      key: 'lessThan'
    },
    {
      text: 'Less than or Equal',
      key: 'lessThanInclusive'
    },
    {
      text: 'Greater Than',
      key: 'greaterThan'
    },
    {
      text: 'Equal or Greater Than',
      key: 'greaterThanInclusive'
    }
    ,
    {
      text: 'One of the following',
      key: 'in'
    }
    ,
    {
      text: 'Not In',
      key: 'notIn'
    },
    {
      text: 'Contains',
      key: 'contains'
    },
    {
      text: 'Does Not Contain',
      key: 'doesNotContain'
    }
  
  ]