import { ChoiceGroup, DefaultButton, DefaultPalette, DetailsList, Dialog, DialogFooter, DialogType, IconButton, Label, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectableOptionMenuItemType, SelectionMode, Separator, Spinner, Stack, Text, TextField, TooltipHost } from "@fluentui/react";
import dayjs from "dayjs";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import useApi from "../../hooks/useApi";
import { usePersistedState } from "../../hooks/usePersistedState";
import { ICustomer } from "../../models/ICustomer";
import { IItem } from "../../models/IItem";
import { ISite } from "../Customer/CustomerContentEditPanel";
import { Accordion } from "../General/Accordion";
import { IReport } from "./ReportContentEditPanel";
import { ReportRenderer } from "./ReportRenderer";

import * as _ from "lodash";
import { ITEMTYPE_FIELD, ITEMTYPE_LOGIC_FIELD, ITEMTYPE_SECTION } from "../../constants/itemTypes";
import { FIELDTYPE_DATETIME } from "../../constants/fieldTypes";
import Autosave from "../General/Autosave";
import { currentDocumentItem, currentMediaItem } from "../../atoms/CustomerAtom";
import { globalSelectListsState } from "../../atoms/SelectListsState";
import { globalProductsState } from "../../atoms/ProductsState";
import ActionEditPanel, { IAction } from "./ActionEditPanel";

export interface IReportFillerProps {
    customer?: ICustomer;
    site?: ISite;
    report_id: string;
    isOpen: boolean;
    dismissFunction: any;
    successFunction: any;
    disabled: boolean;
}

export interface IKeyValue {
    key: string;
    value: any
}



export const ReportFiller = (props: IReportFillerProps) => {

    const [isopen, setisopen] = useState(false);

    // Paging
    const [pageSize, setPageSize] = usePersistedState('CustomerPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('CustomerCurrentPage', 1);

    // Searching


    const [report, setReport] = useState<IReport>();

    const [shadowValues, setShadowValues] = useState(new Map<string, any>())

    const [templateItems, setTemplateItems] = useState<any>([])

    const [mediaItem, setMediaItem] = useRecoilState(currentMediaItem)
    const [document, setDocument] = useRecoilState(currentDocumentItem)

    const api = useApi();
    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    const [selectLists, setSelectLists] = useRecoilState(globalSelectListsState)
    const [products, setProducts] = useRecoilState(globalProductsState)


    const [currentItem, setCurrentItem] = useState<IItem>();
    const [addNewActionOpen, setAddNewActionOpen] = useState(false);
    


    const getSelectLists = async () => {

        if (selectLists && selectLists.length > 0) {
            return;
        }

        try {


            const result = await api.get(`/configuration/lists?noPaging=true`);
            setSelectLists(result.data.data);

        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Select Lists`
            });

        }

    }

    const getProducts = async () => {

        if (products && products.length > 0) {
            return;
        }

        try {


            const result = await api.get(`/configuration/products?noPaging=true&orderBy=name`);
            setProducts(result.data);

        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Report,
                message: `Error Getting Products`
            });

        }

    }

    useEffect(() => {

        getSelectLists();

        getProducts();

    }, [])


    // Set initial Open Value, changes on Props
    useEffect(() => {
        // And open if we've been told to
        setisopen(props.isOpen);

        if (props.isOpen) {
            getReport();


        }
    }, [props]);

    const getItems: any = (item: any) => {
        if (!item.items || item.items.length === 0) {
            return item
        }

        return [item, _.flatMapDeep(item.items, getItems)]
    }


    const getReport = async () => {

        // Use the Customer ID from properties
        try {


            const result = await api.get(`/reports/${props.report_id}`);

            setReport(result.data);

            let flatItems = _.flatMapDeep(result.data.items, getItems).filter((o: any) => o.item_type === ITEMTYPE_FIELD)

            let mapped = new Map<string, any>(
                flatItems.map((oItem: any) => {
                    if (oItem.field_type === FIELDTYPE_DATETIME && oItem.field_value != undefined) {

                        return [oItem.item_id, new Date(oItem.field_value)]
                    }
                    return [oItem.item_id, oItem.field_value]
                })
            )

            let flatTemplateItems: any[] = _.flatMapDeep(result.data.template.items, getItems).filter((o: any) => o.item_type != ITEMTYPE_FIELD)

            setTemplateItems(flatTemplateItems)



            //            console.log('MAPPED',mapped)

            setShadowValues(mapped);

            //            console.log(Object.fromEntries(mapped))





        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Report ${props.report_id}`
            });

        }
    };

    const setShadowValue = (key: string, value: any) => {

        let oldvalue = shadowValues.get(key);
        if (oldvalue != value) {
            setShadowValues(shadowValues.set(key, value));


        }
        //console.log(`${key} Old Value`,shadowValues.get(key))

        //console.log(`${key} New Value`,shadowValues.get(key))

    }

    const getShadowValue = (key: string) => {
        return shadowValues.get(key);
    }


    /*
    "from_item_template_id": "template_item_e151ca19cdd04e8f820326d62964d962",
    "parent_item_id": "report_item_d81d79e873be4ba1a4085e7ae74a4909",
    "label": "Custom Label 99"
*/

    const AddNewAction = (item: IItem) => {

        setCurrentItem(item);
        setAddNewActionOpen(true);
        //console.log('Add New Action')
    }

    const AddNewSection = async (fromItemTemplateID: string, parentItemId: string, label?: string) => {

        //console.log('Add New Section')
        try {

            const result = await api.post(`/reports/${props.report_id}/sections`,
                {
                    from_item_template_id: fromItemTemplateID,
                    parent_item_id: parentItemId,
                    label: label ? label : 'New Section'
                })

            setGlobalMessage({
                action: PanelActionType.Success,
                owner: PanelActionOwner.Report,
                message: `Added Section To ${props.report_id}`
            });
        } catch (e) {

            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Report ${props.report_id}`
            });

        }
    }

   


    const updateFieldValue = async (values: any) => {
        // {{url}}/reports/report_80d364339a6e43dba427f3f2ab8e361d/items/report_item_3c5d2006e6b746679fee267660485192
        // {
        //"field_value": "98"
        //}

        // First Check if values have changed

        /*     {
        "item_id": "report_item_770e91dc545a494fadcdb55cefc2fd65",
        "field_value": "Baz"
    },*/


        try {

            var itemsToUpdate: any[] = [];
            var output = Object.entries(values).map(([key, value]) => ({ key, value }));

            let change: boolean = false;

            output.forEach(async o => {
                //console.log(o)

                let existingValue = shadowValues.get(o.key);

                let different: boolean = false;
                if (typeof o.value === 'object') {
                    if (o.value != existingValue) {
                        different = true;
                    }
                } else {
                    if ((String(o.value) != String(existingValue))) {
                        different = true;
                    }
                }

                if (different) {

                    // TODO Add to batch and call the batch update
                    //             console.log(`Updating ${o.key} with value: `, o.value)

                    console.log('SAVING', o)

                    itemsToUpdate.push({
                        "item_id": o.key,
                        "field_value": o.value
                    })

                    const result = await api.put(`/reports/${props.report_id}/items/${o.key}`,
                        {
                            'field_value': o.value
                        });

                    setShadowValue(o.key, o.value)

                    change = true;
                }


            })

            if (change) {
                getReport();
            }





        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Updating Field Value ${props.report_id}`
            });
        }
    }



    if (!report) {
        return <></>
    }

    return (
        <Panel
            headerText={
                `REPORT: ${report.name}`
            }
            isOpen={isopen}
            onDismiss={() => {
                props.dismissFunction();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.smallFluid}
            overlayProps={{ isDarkThemed: true }}


        >
            <Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <span>Customer</span>
                    <b>{props.customer?.name}</b>
                    <span>Site</span>
                    <b>{props.site?.name}, {props.site?.address1}, {props.site?.postcode}</b>
                </Stack>
                <Text>STATUS: {report.status}</Text>
                <Text>CREATED: {dayjs.unix(report.created_at).format('DD/MM/YYYY h:mm a')}</Text>
                <Text>TEMPLATE: {report.template.name}</Text>



                {report.items?.length && <Formik
                    enableReinitialize
                    onSubmit={async (
                        values,
                        { }
                    ) => {

                        //console.log('Values', values)


                    }}



                    validate={(value: any) => {
                        // At this point compare value to original and if different write back to report


                        // At this point setFieldValueFunction is null, doh!



                        console.log('Validating', value)
                        updateFieldValue(value);



                    }}


                    validateOnMount={false}
                    validateOnBlur={true}
                    validateOnChange={true}


                    initialValues={Object.fromEntries(shadowValues)}
                >
                    {({ isValid, values, validateField, setFieldValue }) => (




                        <Form >
                            <Stack styles={{
                                root: {
                                    marginBottom: 12
                                },
                            }}>

                                <Pivot>
                                    {report.items?.map((o: IItem, index) => {
                                        return (<PivotItem key={index} headerText={o.label}>
                                            <ReportRenderer initialValues={shadowValues} shadowGetValueFunction={getShadowValue} shadowSetValueFunction={setShadowValue} updateFunction={updateFieldValue} isTopLevel={false} templateItems={templateItems} items={o.items} validateForm={validateField} addNewSectionFunction={AddNewSection} addNewActionFunction={AddNewAction} setFieldValueFunction={setFieldValue} disabled={props.disabled} />
                                            {o.from_item_template_id && templateItems.filter((f: IItem) => {
                                                return (f.parent_item_id === o.from_item_template_id && f.item_type === ITEMTYPE_SECTION && f.is_repeatable_section === true)
                                            }).map((templateItem: IItem, index: number) => {
                                                return <Separator key={index}>
                                                    <TooltipHost
                                                        content={`Add repeatable Section '${templateItem.label}'`}
                                                        // This id is used on the tooltip itself, not the host
                                                        // (so an element with this id only exists when the tooltip is shown)
                                                        id={`addsubsection`}

                                                    >
                                                        <IconButton aria-describedby="addsubsection" iconProps={{ iconName: "DependencyAdd" }} onClick={() => { templateItem.item_id && o.item_id && AddNewSection(templateItem.item_id, o.item_id, templateItem.label) }} />
                                                    </TooltipHost>

                                                </Separator>
                                            })}

                                        </PivotItem>)
                                    })}
                                </Pivot>





                            </Stack>
                            <Stack horizontal horizontalAlign="end">
                                {report.status != 'Completed' && <PrimaryButton type="submit" disabled={!isValid || props.disabled}>Submit </PrimaryButton>}
                            </Stack>
                            <Autosave experimentData={values} />

                        </Form>



                    )}

                </Formik>}

            



            </Stack>
            {/*}
            <Accordion header='Debug' color={DefaultPalette.red}>
                <pre>{JSON.stringify(report, null, 2)}</pre>
                                </Accordion>*/}

            {currentItem  && <ActionEditPanel report_id={props.report_id} item={currentItem} isOpen={addNewActionOpen} dismissFunction={() => setAddNewActionOpen(false)} action={currentItem.actions && currentItem.actions?.length > 0 ? currentItem.actions[0] : undefined} successFunction={(values:IAction) => {getReport();setAddNewActionOpen(false)}} formMode={currentItem.actions && currentItem.actions?.length > 0 ? FormMode.Edit : FormMode.Add} />}


        </Panel>
    )

}
