import { DefaultPalette, Text, DetailsList, IconButton, Label, Link, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Separator, Spinner, Stack, TooltipHost, ActionButton, IBreadcrumbItem, Breadcrumb, getAllSelectedOptions, StackItem } from "@fluentui/react";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../atoms/GlobalMessage";
import { globalSelectListsState } from "../atoms/SelectListsState";
import { Accordion } from "../components/General/Accordion";
import { ReportFiller } from "../components/Reports/ReportFiller";
import { FIELDTYPE_DATETIME, FIELDTYPE_MEDIA } from "../constants/fieldTypes";
import { ITEMTYPE_FIELD, ITEMTYPE_SECTION } from "../constants/itemTypes";
import { PanelActionType, PanelActionOwner, FormMode } from "../enums";
import useApi from "../hooks/useApi";
import { IItem } from "../models/IItem";
import { IReport } from "../models/IReport.ts";
import { useNavigate } from "react-router-dom";
import { globalProductsState } from "../atoms/ProductsState";
import ActionEditPanel, { IAction, IActionLineItem } from "../components/Reports/ActionEditPanel";
import { IProduct } from "../interfaces/IProduct";
import { numberFormat } from "../numberFormat";
import { DisplayBlock } from "../components/General/DisplayBlock";
import { IDocument } from "../interfaces/IDocument";
import { ExportType } from "../enums/ExportType";
import { IExportResult } from "../interfaces/IExportResult";
import { useTenantInfo }  from "../hooks/useTenantInfo";





const Report = () => {


    const { id } = useParams();

    const [report, setReport] = useState<IReport>();

    const [shadowValues, setShadowValues] = useState(new Map<string, any>())

    const [templateItems, setTemplateItems] = useState<any>([])

    const [flatItems, setFlatItems] = useState<any>([])

    const tenantInfo = useTenantInfo();


    const api = useApi();
    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    const [isFillOutReportOpen, setIsFillOutReportOpen] = useState<boolean>(false)

    const [selectLists, setSelectLists] = useRecoilState(globalSelectListsState)

    const [editReport, setEditReport] = useState<Boolean>(false)

    const [exportLink, setExportLink] = useState<IExportResult>()

    const [waitingCSV, setWaitingCSV] = useState<boolean>(false)
    const [waitingPDF, setWaitingPDF] = useState<boolean>(false)

    const navigate = useNavigate();

    const [products, setProducts] = useRecoilState(globalProductsState)

    const [currentItem, setCurrentItem] = useState<IItem>();
    const [addNewActionOpen, setAddNewActionOpen] = useState(false);

    const getSelectLists = async () => {

        if (selectLists && selectLists.length > 0) {
            return;
        }

        try {
            const result = await api.get(`/configuration/lists?noPaging=true`);
            setSelectLists(result.data.data);

        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Select Lists`
            });

        }

    }

    const getProducts = async () => {

        if (products && products.length > 0) {
            return;
        }

        try {


            const result = await api.get(`/configuration/products?noPaging=true&orderBy=name`);

            setProducts(result.data.data);

        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Report,
                message: `Error Getting Products`
            });

        }

    }



    useEffect(() => {

        getSelectLists();

        getProducts();

        getReport();

    }, [])


    function getBreadcrumbItems() {

        let items: IBreadcrumbItem[] = [];

        items.push({
            text: 'Customers', key: 'customers', onClick: () => navigate('/customers')
        });

        report?.customer && items.push(
            {
                text: report.customer.name, key: 'customer', onClick: () => navigate(`/customer/${report.customer_id}`)
            });

        report?.site && items.push(
            {
                text: report.site.name, key: 'site', onClick: () => navigate(`/reports/${report.customer_id}/${report.site_id}`)
            });

        report && items.push(
            {
                text: report.name, key: 'report', onClick: () => { }
            });

        return items;
    }

    const getSubTotal = (actionItems: IActionLineItem[]) => {

        let total: number = 0;


        actionItems.forEach((item: IActionLineItem) => {



            const product: IProduct = products.filter((o: IProduct) => {
                return o.code === item.item_code;
            })[0];

            if (product) {
                total += item.quantity * (product.unit_price / 100);
            }

            // console.log('item quantity', item.quantity)
            //  console.log('item price', product.unit_price)

        })


        return total;
    }

    const getTotal = (items: any[]) => {

        if (items.length === 0) {
            return 0
        }

        let total: number = 0;

        items.forEach((item: IItem) => {

            total += item.actions ? getSubTotal(item.actions[0].line_items) : 0;
        })

        return total;
    }


    const getItems: any = (item: any) => {
        if (!item.items || item.items.length === 0) {
            return item
        }

        return [item, _.flatMapDeep(item.items, getItems)]
    }



    const getReportFile = async (exportType: ExportType) => {

        const exportTypeAsString: string = exportType.toString();

        const result = await api.post(`/export/reports/${id}/${exportTypeAsString}`);



        setExportLink({
            access_url: result.data.access_url,
            filename: result.data.filename,
            type: exportType
        })
    }


    const getReport = async () => {

        // Use the Customer ID from properties
        try {


            const result = await api.get(`/reports/${id}?expand=customer,site`);

            setReport(result.data);

            let flatItems: any[] = _.flatMapDeep(result.data.items, getItems).filter((o: any) => o.item_type === ITEMTYPE_FIELD)

            setFlatItems(flatItems)
            let mapped = new Map<string, any>(
                flatItems.map((oItem: any) => {
                    if (oItem.field_type === FIELDTYPE_DATETIME && oItem.field_value != undefined) {

                        return [oItem.item_id, new Date(oItem.field_value)]
                    }
                    return [oItem.item_id, oItem.field_value]
                })
            )

            let flatTemplateItems: any[] = _.flatMapDeep(result.data.template.items, getItems).filter((o: any) => o.item_type !== ITEMTYPE_FIELD)

            setTemplateItems(flatTemplateItems)



            //            console.log('MAPPED',mapped)

            setShadowValues(mapped);

            //            console.log(Object.fromEntries(mapped))





        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Report ${id}`
            });

        }
    };



    if (!report) {
        return <Spinner />
    }



    return (
        <>
            <Breadcrumb
                items={getBreadcrumbItems()}
                maxDisplayedItems={10}
                ariaLabel="Breadcrumb with items rendered as buttons"
                overflowAriaLabel="More links"
            />
            <Separator />
            
            <Stack>
                 {!tenantInfo?.roles.includes('customer_viewer') && <Stack horizontal>
                    <ActionButton iconProps={{ iconName: "view" }} onClick={() => { setIsFillOutReportOpen(true); setEditReport(false) }} >View Report</ActionButton>

                    <ActionButton disabled={tenantInfo?.roles.includes('user') || report.status === 'Completed'} iconProps={{ iconName: "edit" }} onClick={() => { setIsFillOutReportOpen(true); setEditReport(true) }} >Edit Report</ActionButton>

                    {waitingPDF && <Spinner />}
                    <ActionButton disabled={waitingPDF} iconProps={{ iconName: "PDF" }} onClick={async () => {
                        setWaitingPDF(true);
                        try {
                            await getReportFile(ExportType.pdf);
                        } catch (e) {
                            console.error(e);
                        }
                     
                        setWaitingPDF(false);

                    }} >Inspection Report</ActionButton>

                    {waitingCSV && <Spinner />}
                    <ActionButton disabled={waitingCSV} iconProps={{ iconName: "ExcelLogo" }} onClick={async () => {
                        setWaitingCSV(true);
                        try {
                        await getReportFile(ExportType.csv);
                        } catch (e) {
                            console.error(e);
                        }
                        setWaitingCSV(false);

                    }} >Executive Summary Report</ActionButton>



                </Stack>}

                {exportLink && !waitingCSV && !waitingPDF && <><Text>Download Is Now Available: </Text><Link target={'_blank'} href={exportLink.access_url}>Click here to download report</Link></>}

                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                    <Label>Customer:</Label>
                    <Text>{report.customer?.name}</Text>
                    <Label>Site:</Label>
                    <Text>{report.site?.name}</Text>
                </Stack>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                    <Label>Status:</Label>
                    <Text>{report.status}{report.status === 'Completed' && ' (Archived)'}</Text>
                    <Label>Created:</Label>
                    <Text>{dayjs.unix(report.created_at).format('DD/MM/YYYY h:mm a')}</Text>
                    <Label>Template:</Label>
                    <Text>{report.template?.name}</Text>
                    <Label>Actions Total:</Label>
                    <Text>{numberFormat(getTotal(flatItems.filter((oActionItem: IItem) => {
                        return oActionItem.actions && oActionItem.actions.length > 0
                    })))}</Text>

                </Stack>

                <Stack styles={{ root: { margin: 10 } }} >

                    <DisplayBlock header={'Images'} children={flatItems.filter((oItem: IItem) => {

                        return oItem.allow_attachments && oItem.attachments && oItem.attachments
                    }).map((item: IItem, index: number) => {
                        return <Stack key={index}>{item.attachments && item.attachments.map((doc: IDocument, i: number) => {
                            return <Stack key={i} ><Link target="_blank" href={doc.access_url}>{doc.name}</Link>
                          
                            {doc.location && doc.location != '' && <Link target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${doc.location}`}>{` - View on  Google Maps: ${doc.location}`}</Link>} 
                            <Separator /></Stack>
                        })}
                       
                        </Stack>
                    })} />

                    <hr />
                    {!tenantInfo?.roles.includes('customer_viewer') && <DisplayBlock header={'Notes'} children={flatItems.filter((oItem: IItem) => {

                        return oItem.allow_notes && oItem.notes
                    }).map((item: IItem, index: number) => {
                        return <Stack key={index}><Label>Question:</Label>
                        <Text>{item.label}</Text>
                        <Label>Note:</Label><Text>{item.notes}</Text></Stack>
                    })} />}
                    <hr />

                    {/* Action Items */}
                    {!tenantInfo?.roles.includes('customer_viewer') && flatItems.filter((oActionItem: IItem) => {
                        return oActionItem.actions && oActionItem.actions.length > 0
                    }).map((o: IItem, index: number) => {
                        return (<Stack key={index}>

                            {o.actions?.map((action, i) => {

                                return (<Stack key={i}><StackItem></StackItem>

                                    <DisplayBlock header={<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                                        <Label>Question:</Label>
                                        <Text>{o.label}</Text>
                                        <Label>Action:</Label>
                                        <Text>{action.description} (TOTAL : <b>{numberFormat(getSubTotal(action.line_items))})</b></Text>
                                        <TooltipHost
                                            content={`Edit Line Items for '${action.description}'`}
                                            // This id is used on the tooltip itself, not the host
                                            // (so an element with this id only exists when the tooltip is shown)
                                            id={action.action_id + '-view'}
                                        >
                                            <IconButton aria-describedby={action.action_id + '-view'} iconProps={{ iconName: 'Edit' }} onClick={() => { setCurrentItem(o); setAddNewActionOpen(true) }} />
                                        </TooltipHost>
                                    </Stack>}>

                                        <Label>Line Items:</Label>

                                        <DetailsList columns={[
                                            {
                                                key: 'item_code',
                                                name: 'Product Code',
                                                fieldName: 'item_code',
                                                minWidth: 100,
                                                maxWidth: 100
                                            },
                                            {
                                                key: 'description',
                                                name: 'Description',
                                                fieldName: 'description',
                                                minWidth: 100,
                                                maxWidth: 400
                                            },
                                            {
                                                key: 'quantity',
                                                name: 'Quantity',
                                                fieldName: 'quantity',
                                                minWidth: 100,
                                                maxWidth: 100
                                            },
                                            {

                                                key: 'price',
                                                name: 'Price',
                                                minWidth: 100,
                                                maxWidth: 100,
                                                onRender: (item: IActionLineItem) => {


                                                    const product: IProduct = products.filter((o: IProduct) => {
                                                        return o.code === item.item_code
                                                    })[0]

                                                    return product ? <Text>{numberFormat(product.unit_price / 100)} {product.currency}</Text> : null
                                                }
                                            },
                                            {

                                                key: 'total',
                                                name: 'Total',
                                                minWidth: 100,
                                                maxWidth: 100,
                                                onRender: (item: IActionLineItem) => {

                                                    const product: IProduct = products.filter((o: IProduct) => {
                                                        return o.code === item.item_code
                                                    })[0]




                                                    return product ? <Text>{numberFormat((product.unit_price / 100) * item.quantity)} {product.currency}</Text> : null
                                                }
                                            }

                                        ]} items={action.line_items} />

                                    </DisplayBlock>





                                </Stack>)
                            })}

                        </Stack>)
                    })}
                </Stack>


                {report && <ReportFiller disabled={!editReport} customer={report.customer} site={report.site} report_id={report.report_id} isOpen={isFillOutReportOpen} dismissFunction={() => { setIsFillOutReportOpen(false); getReport() }} successFunction={undefined} />}


                </Stack>

            <Separator />
            {/*}
            <Accordion header='Debug' color={DefaultPalette.red}>
                <pre>{JSON.stringify(report, null, 2)}</pre>
                </Accordion>*/}
            {currentItem && <ActionEditPanel isOpen={addNewActionOpen} dismissFunction={() => { setAddNewActionOpen(false); getReport(); }} successFunction={(values: IAction) => { getReport(); setAddNewActionOpen(false) }} item={currentItem} report_id={report.report_id} formMode={FormMode.Edit} action={currentItem.actions && currentItem.actions?.length > 0 ? currentItem.actions[0] : undefined} />}       </>
    )



}

export default Report