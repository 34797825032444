import { IconButton, Panel, PanelType, Stack, StackItem, Text, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import useApi from "../../hooks/useApi";
import { usePersistedState } from "../../hooks/usePersistedState";
import { ICustomer } from "../../models/ICustomer";
import customers from "../../pages/customers";
import { DataTable } from "../General/DataTable";
import PageTitle from "../layout/pageTitle";
import { useId } from "@fluentui/react-hooks";
import { SiteEditPanel } from "../Site/SiteEditPanel";
import { ReportContentEditPanel } from "../Reports/ReportContentEditPanel";
import { useTenantInfo } from "../../hooks/useTenantInfo";




export interface ISite {
    site_id: string;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    city: string;
    county: string;
    country: string;
    postcode: string;
    notes: string;
}

export interface ICustomerContentEditProps {
    customer: ICustomer;
    isOpen: boolean;
    dismissFunction: any;
    successFunction: any;
    formMode: FormMode;
}

export const CustomerContentEditPanel = (props: ICustomerContentEditProps) => {

    const [isopen, setisopen] = useState(false);
    const [customer, setCustomer] = useState<ICustomer>();
    const [customerSites, setCustomerSites] = useState<ISite[]>([]);
    const [totalCustomerSites, setTotalCustomerSites] = useState(0);
    const [currentSite, setCurrentSite] = useState<ISite>();

    const [isEditSiteOpen, setIsEditSiteOpen] = useState(false);
    const [isViewReportsOpen, setIsViewReportsOpen] = useState(false);

    // Paging
    const [pageSize, setPageSize] = usePersistedState('CustomerPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('CustomerCurrentPage', 1);

    // Searching
    const [searchTerm, setSearchTerm] = useState("");

    const detailTooltipId = useId('detailTooltip');

    const tenantInfo = useTenantInfo();


    const api = useApi();
    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    const getCustomer = async () => {

        // Use the Customer ID from properties
        try {


            const result = await api.get(`/customers/${props.customer.customer_id}`);

            setCustomer(result.data);


        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Customer ${props.customer.customer_id}`
            });

        }
    }

    const getCustomerSites = async () => {

        // Use the Customer ID from properties
        try {


            const result = await api.get(`/sites?customer_id=${props.customer.customer_id}&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`);


            setCustomerSites(result.data.data);

            setTotalCustomerSites(result.data.count)
        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Customer Sites ${props.customer.customer_id}`
            });

        }
    }

    // Set initial Open Value, changes on Props
    useEffect(() => {
        // And open if we've been told to
        setisopen(props.isOpen);

       

    }, [props, searchTerm]);

        // Whenever the change, search or pagesize changes, get the data
        useEffect(() => {
            if (props.isOpen) {
                getCustomer();
                getCustomerSites();
            }
        }, [currentPage, searchTerm, pageSize]);

    return (
        <Panel
            headerText={
                `CUSTOMER: ${customer?.name}`
            }
            isOpen={isopen}
            onDismiss={() => {
                props.dismissFunction();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.smallFluid}
            overlayProps={{ isDarkThemed: true }}


        >
            <Stack tokens={{ childrenGap: 10 }}>
                <StackItem>
                    {customer?.address1 && <Text>{customer?.address1}</Text>}
                    {customer?.address2 && <Text> ,{customer?.address2}</Text>}
                    {customer?.city && <Text> ,{customer?.city}</Text>}
                    {customer?.county && <Text> ,{customer?.county}</Text>}
                    {customer?.country && <Text> ,{customer?.country}</Text>}
                    {customer?.postcode && <Text> ,{customer?.postcode}</Text>}
                </StackItem>

                <Text variant="large">Sites</Text>

                <Text>Number of Sites: {totalCustomerSites}</Text>



                <DataTable
                    newButtonText="New Site"
                    hideNewButton={tenantInfo?.roles.includes('customer_viewer')}
                    pageSize={pageSize}
                    columns={
                        [

                            {
                                key: 'name',
                                name: 'Site Name',
                                fieldName: 'name',
                                minWidth: 100
                            },

                            {
                                key: 'address1',
                                name: 'Address 1',
                                fieldName: 'address1',
                                minWidth: 100
                            },
                            {
                                key: 'postcode',
                                name: 'PostCode',
                                fieldName: 'postcode',
                                minWidth: 100
                            },
                            {

                                key: 'actions',
                                name: 'Actions',
                                minWidth: 100,
                                isResizable: false,
                                onRender: (item) => (
                                    <Stack horizontal>




                                        <TooltipHost
                                            content="Edit Site"
                                            // This id is used on the tooltip itself, not the host
                                            // (so an element with this id only exists when the tooltip is shown)
                                            id={detailTooltipId}
                                        >

                                            <IconButton aria-describedby={detailTooltipId} iconProps={{ iconName: 'Edit' }} onClick={() => { setCurrentSite(item); setIsEditSiteOpen(true) }
                                            } />
                                        </TooltipHost>

                                        <TooltipHost
                                            content="View Site Reports"
                                            // This id is used on the tooltip itself, not the host
                                            // (so an element with this id only exists when the tooltip is shown)
                                            id={detailTooltipId+'-reports'}
                                        >

                                            <IconButton aria-describedby={detailTooltipId+'-reports'} iconProps={{ iconName: 'View' }} onClick={() => { setCurrentSite(item); setIsViewReportsOpen(true) }
                                            } />
                                        </TooltipHost>


                                    </Stack>
                                ),
                            },
                        ]
                    }
                    items={customerSites}
                    totalItems={totalCustomerSites}
                    onNewItemClick={() => {
                        setCurrentSite(undefined); setIsEditSiteOpen(true)
                    }}
                    onItemClicked={(item: ISite) => {
                        //setCurrentCustomer(item); setOpenEditPanel(true)
                        setCurrentSite(item); setIsViewReportsOpen(true)
                    }}
                    currentPage={currentPage}
                    onPaginationClick={(page: number) => {
                        setCurrentPage(page);
                    }}
                    onSearchTextChange={(text: string) => {
                        setCurrentPage(1);
                        setSearchTerm(text);
                    }}
                    onPageSizeChange={(size: number) => {
                        setPageSize(size);
                    }} />





            </Stack>

            <SiteEditPanel formMode={currentSite ? FormMode.Edit : FormMode.Add} isOpen={isEditSiteOpen} site_id={currentSite ? currentSite.site_id : ""} dismissFunction={() => setIsEditSiteOpen(false)} successFunction={() => {
                setCurrentSite(undefined);
                setIsEditSiteOpen(false);
                getCustomerSites();
            } } customer_id={props.customer.customer_id} />


            <ReportContentEditPanel site={currentSite} customer={props.customer} isOpen={isViewReportsOpen} dismissFunction={() => setIsViewReportsOpen(false)} successFunction={ () => { 
                 setCurrentSite(undefined);
                 setIsEditSiteOpen(false);
                 getCustomerSites();
            }} formMode={FormMode.Edit} />
        </Panel>)
}

