import { AnimationClassNames, Layer, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { PanelActionType } from "../../enums";
import { IMessage } from "../../interfaces/IMessage";
import { messageBarStyles, pageContainerStyles } from "../../styles";

type PageContainerProps = {
  children: React.ReactNode
}


const PageContainer = ( props: PageContainerProps ) => {

  const [globalMessage] = useRecoilState(globalMessageState)
  
  

  const [message, setMessage] = useState<IMessage>({
    show: false,
    type: MessageBarType.info,
    text: "",
  });

  useEffect(() => {

    switch (globalMessage.action) {
      case PanelActionType.Success:
        _showMessage(MessageBarType.success, globalMessage.message);
        break;
      case PanelActionType.Error:
        _showMessage(MessageBarType.error, globalMessage.message);
        break;
    }

  },[globalMessage])

 
  function _hideMessage() {
    setMessage({ ...message, show: false });
    
  }

  // Probably need to move these in to a library
  function _showMessage(type: MessageBarType, text: String) {
    
  
    setMessage({
      show: true,
      type: type,
      text: text,
    });
    setTimeout(() => {
      _hideMessage();
    }, 2000);
  }

  return <Stack style={pageContainerStyles}>
    {message.show && (
      <Layer><MessageBar
        styles={messageBarStyles}
        messageBarType={message.type}
        onDismiss={_hideMessage}
        className={AnimationClassNames.slideDownIn20}
      >
        {message.text}
      </MessageBar></Layer>
    )}{props.children}</Stack>;
};

export default PageContainer;
