import { useEffect, useState } from "react";
import {
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  sizeToPixels,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { Formik, Form, Field, FormikHelpers, yupToFormErrors, validateYupSchema } from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { ISiteEditProps } from "./ISiteEditProps";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";

import useApi from "../../hooks/useApi";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { ISite } from "../Customer/CustomerContentEditPanel";


const schemaSite = Yup.object().shape({
  name: Yup.string().required("You must enter a site name"),
  address1: Yup.string().required("You must enter a site address line 1"),
  address2: Yup.string(),
  address3: Yup.string(),
  address4: Yup.string(),
  city: Yup.string().required("You must enter a town / city"),
  county: Yup.string(),
  country: Yup.string(),
  postcode: Yup.string().required("You must enter a postcode"),
  notes: Yup.string()


});

interface ISiteFormValues {
  name: string;
  address1: string;
  address2: string;
  address3: string,
  address4: string,
  city: string,
  county: string,
  country: string,
  postcode: string,
  notes: string

  
}

export const SiteEditPanel = (props: ISiteEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [site, setSite] = useState<ISite>();
  const [,setGlobalMessage] = useRecoilState(globalMessageState)

  const api = useApi();

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getSite();
  }, [props]);

  const getSite = async () => {

    // Use the Customer ID from properties
    try {


      const result = await api.get(`/sites/${props.site_id}`);

      setSite(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Site ${props.site_id}`
      });

    }
  };

  if (!site && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  return (
    <Panel
      headerText={
        props.formMode === FormMode.Add ? "New Customer Site" : "Edit Customer Site"
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      overlayProps={{ isDarkThemed: true }}

    >
      <Label>{site?.site_id}</Label>
      <Formik
        enableReinitialize
        initialValues={
          props.formMode === FormMode.Add
            ? {
              name: "",
              address1: "",
              address2: "",
              address3: "",
              address4: "",
              city: "",
              county: "",
              country: "",
              postcode: "",
              notes: ""
            

            }
            : {
              name: site ? site.name : "",
              address1: site ? site.address1 : "",
              address2: site ? site.address2 : "",
              address3: site ? site.address3 : "",
              address4: site ? site.address4 : "",
              city: site ? site.city : "",
              county: site ? site.county : "",
              country: site ? site.country : "",
              postcode: site ? site.postcode : "",
              notes: site ? site.notes : ""
            

            }
        }
        validationSchema={schemaSite}
        onSubmit={async (
          values: ISiteFormValues,
          { setSubmitting }: FormikHelpers<ISiteFormValues>
        ) => {
          // values has the values
          // console.log(values);


          try {
            let result =
              props.formMode === FormMode.Edit
                ? await api.put(`/sites/${props.site_id}`, {
                  name: values.name,
                  address1: values.address1,
                  address2: values.address2,
                  address3: values.address3,
                  address4: values.address4,
                  city: values.city,
                  county: values.county,
                  country: values.country,
                  postcode: values.postcode,
                  notes: values.notes
              

                })
                : await api.post(`/sites`, {
                  customer_id: props.customer_id,
                  name: values.name,
                 

                });

                setGlobalMessage({
                  action: PanelActionType.Success,
                  owner: PanelActionOwner.Customer,
                  message: "Site Create/Update Success"
                });

            props.successFunction();

          } catch (error) {
            console.log('Error', error)

            setGlobalMessage({
              action: PanelActionType.Error,
              owner: PanelActionOwner.Customer,
              message: `Error Creating/Updating Site ${site?.name}`
            });

          }
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <Stack styles={{
              root: {
                marginBottom: 12
              },
            }}>
              <Label htmlFor="name">Site Name</Label>
              <Field name="name" id="name" component={FormikTextField} />
              <Label htmlFor="address1">Address Line 1</Label>
              <Field name="address1" id="address1" component={FormikTextField} />
              <Label htmlFor="address2">Address Line 2</Label>
              <Field name="address2" id="address2" component={FormikTextField} />
              <Label htmlFor="address3">Address Line 3</Label>
              <Field name="address3" id="address2" component={FormikTextField} />
              <Label htmlFor="address4">Address Line 4</Label>
              <Field name="address4" id="address2" component={FormikTextField} />
              <Label htmlFor="city">City</Label>
              <Field name="city" id="city" component={FormikTextField} />
              <Label htmlFor="county">County</Label>
              <Field name="county" id="county" component={FormikTextField} />
              <Label htmlFor="country">Country</Label>
              <Field name="country" id="country" component={FormikTextField} />
              <Label htmlFor="postcode">PostCode</Label>
              <Field name="postcode" id="postcode" component={FormikTextField} />
              <Label htmlFor="notes">Notes</Label>
              <Field name="notes" id="notes" component={FormikTextField} multiline rows={5} />
              
            </Stack>
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Panel>
  );
};


