import { Stack, TooltipHost, IconButton, Panel, PanelType, Text, StackItem } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import useApi from "../../hooks/useApi";
import { usePersistedState } from "../../hooks/usePersistedState";
import { IReportTemplate } from "../../models/IReportTemplate";
import { DataTable } from "../General/DataTable";
import { useId } from "@fluentui/react-hooks";
import { ISite } from "../Customer/CustomerContentEditPanel";
import { ICustomer } from "../../models/ICustomer";
import { ReportEditPanel } from "./ReportEditPanel";
import { ReportFiller } from "./ReportFiller";
import dayjs from "dayjs";

export interface IReport {
    created_at: number;
    name: string;
    tenant_id: string;
    customer_id: string;
    report_id: string;
    site_id: string;
    from_template_id: string;
    template: IReportTemplate;
    status: string;
    items: any[];
}

export interface IReportPost {
    name: string;
    customer_id?: string;
    site_id?: string;
    from_template_id: string;
}

export interface IReportContentEditProps {
    customer?: ICustomer;
    site?: ISite;
    isOpen: boolean;
    dismissFunction: any;
    successFunction: any;
    formMode: FormMode;
}

export const ReportContentEditPanel = (props: IReportContentEditProps) => {

    const [isopen, setisopen] = useState(false);
    const [reports, setReports] = useState<IReport[]>([]);
    const [totalReports, setTotalReports] = useState(0);
    const [currentReport, setCurrentReport] = useState<IReport>();



    const [isEditReportOpen, setIsEditReportOpen] = useState(false);

    const [isFillOutReportOpen, setIsFillOutReportOpen] = useState(false);
    // Paging
    const [pageSize, setPageSize] = usePersistedState('reportsPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('reportsCurrentPage', 1);
    const [searchTerm, setSearchTerm] = useState("");


    const detailTooltipId = useId('detailTooltip');

    const api = useApi();
    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    const getReports = async () => {

        // Use the Customer ID from properties
        try {

            let url = '/reports';

            if (props.site) {

                //console.log('Site ID', props.site.site_id);

                url = url + `?siteid=${props.site.site_id}`;

            } else {

                if (props.customer) {
                    //console.log('Customer ID', props.customer.customer_id);


                    url = url + `?customerid=${props.customer.customer_id}`;



                }




            }

            url = url + `&select=created_at,status,name,report_id,template.name&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`;





            const result = await api.get(url);


            setReports(result.data.data);
            setTotalReports(result.data.count);



        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Reports`
            });

        }
    }

    // Set initial Open Value, changes on Props
    useEffect(() => {
        // And open if we've been told to
        setisopen(props.isOpen);

        if (props.isOpen) {
            getReports();
        }

    }, [props, searchTerm, pageSize]);

    return (
        <Panel
            headerText={
                `REPORTS`
            }
            isOpen={isopen}
            onDismiss={() => {
                props.dismissFunction();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.smallFluid}
            overlayProps={{ isDarkThemed: true }}


        >
            <Stack tokens={{ childrenGap: 10 }}>

                {props.customer && <Text>CUSTOMER: {props.customer.name}</Text>}

                {props.site && <Text>SITE: {props.site.name}</Text>}
                <DataTable
                    newButtonText="New Report"
                    pageSize={pageSize}
                    columns={
                        [
                            {
                                key: 'created_at',
                                name: 'Created',
                                fieldName: 'created_at',
                                maxWidth: 150,
                                minWidth: 150,
                                onRender: (item) => (
                                    <span>{dayjs.unix(item.created_at).format('DD/MM/YYYY h:mm a')}</span>
                                )
                            },
                            {
                                key: 'name',
                                name: 'Report Name',
                                fieldName: 'name',
                                minWidth: 300
                            }
                            ,
                            {
                                key: 'templatename',
                                name: 'Template Name',
                                fieldName: 'template.name',
                                minWidth: 200,
                                onRender: (item: IReport) => (
                                    item.template.name
                                )
                            },

                            {

                                key: 'actions',
                                name: 'Actions',
                                minWidth: 100,
                                isResizable: false,
                                onRender: (item) => (
                                    <Stack horizontal>


                                        <TooltipHost
                                            content="Fill Out Report"
                                            // This id is used on the tooltip itself, not the host
                                            // (so an element with this id only exists when the tooltip is shown)
                                            id={detailTooltipId + 'editreportcontent'}
                                        >

                                            <IconButton aria-describedby={detailTooltipId + 'editreportcontent'} iconProps={{ iconName: 'Handwriting' }} onClick={() => { setCurrentReport(item); setIsFillOutReportOpen(true) }
                                            } />
                                        </TooltipHost>

                                        <TooltipHost
                                            content="Edit Report Properties"
                                            // This id is used on the tooltip itself, not the host
                                            // (so an element with this id only exists when the tooltip is shown)
                                            id={detailTooltipId}
                                        >

                                            <IconButton aria-describedby={detailTooltipId} iconProps={{ iconName: 'Edit' }} onClick={() => { setCurrentReport(item); setIsEditReportOpen(true) }
                                            } />
                                        </TooltipHost>

                                    </Stack>
                                ),
                            },
                        ]
                    }
                    items={reports}
                    totalItems={totalReports}
                    onNewItemClick={() => {
                        setCurrentReport(undefined); setIsEditReportOpen(true)
                    }}
                    onItemClicked={(item: IReport) => {
                        //setCurrentCustomer(item); setOpenEditPanel(true)
                        setCurrentReport(item); setIsFillOutReportOpen(true)
                    }}
                    currentPage={currentPage}
                    onPaginationClick={(page: number) => {
                        setCurrentPage(page);
                    }}
                    onSearchTextChange={(text: string) => {
                        setCurrentPage(1);
                        setSearchTerm(text);
                    }}
                    onPageSizeChange={(size: number) => {
                        setPageSize(size);
                    }} />


            </Stack>

            <ReportEditPanel formMode={currentReport ? FormMode.Edit : FormMode.Add} isOpen={isEditReportOpen} site_id={props.site ? props.site.site_id : ''} dismissFunction={() => setIsEditReportOpen(false)} successFunction={() => {
                setCurrentReport(undefined);
                setIsEditReportOpen(false);
                getReports();
            }} customer_id={props.customer ? props.customer.customer_id : ''} report_id={currentReport ? currentReport.report_id : ''} />

            {currentReport && <ReportFiller disabled={false} customer={props.customer} site={props.site} report_id={currentReport.report_id} isOpen={isFillOutReportOpen} dismissFunction={() => setIsFillOutReportOpen(false)} successFunction={() => {
                setIsFillOutReportOpen(false);
            }} />}

        </Panel>
    )

}

