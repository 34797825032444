
import { atom } from "recoil";
import { IDocument } from "../interfaces/IDocument";
import { ICustomer } from "../models/ICustomer";
import { IItem } from "../models/IItem";

interface ICurrentMediaItem {
  item? : IItem;
  isOpen: boolean;
}

interface IDocumentItem {
  document?: IDocument,
  item?: IItem;
  isViewerOpen: boolean,
  isDeleteOpen: boolean,
  isEditOpen: boolean
}

  export const customerState = atom({
    key: 'customer', // unique ID (with respect to other atoms/selectors)
    default:[] as ICustomer [] // default value (aka initial value)
  });

  export const setFormValue = atom({
    key: 'setformvalue', // unique ID (with respect to other atoms/selectors)
    default: null // default value (aka initial value)
  });

  export const currentMediaItem = atom({
    key: 'currentmediaitem',
    default: {
      } as ICurrentMediaItem
    }
  )

  export const currentDocumentItem = atom({
    key: 'currentdocumentitem',
    default: {
      } as IDocumentItem
    }
  )