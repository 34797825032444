import { AnimationClassNames, DefaultPalette, IconButton, IStackItemStyles, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

interface IAccordionProps {
  header: any;
  actions?: any;
  isExpanded?: boolean;
  color?: string;
  children: any;
}

export class Accordion extends React.Component<IAccordionProps, {}> {


  

  private accordionHeader: IStackItemStyles = {
    root: {
      background: DefaultPalette.neutralLighter,
      padding: 5,
      cursor: "pointer",
      borderLeftStyle: "solid",
      borderLeftColor: this.props.color,
      borderLeftWidth: 5

    }
  };

  private accordion: IStackStyles = {
    root: {
      borderWidth: 1,
      marginTop: 10,
      marginLeft: 10,
      marginRight: 2,
      marginBottom: 2,
      borderColor: this.props.color,
      borderStyle: "solid"


    }
  };

  private accordionContent: IStackStyles = {
    root: {
      borderWidth: 1,
      padding: 10,
      borderColor: this.props.color
    }
  };

  constructor(props: IAccordionProps, public state: any) {
    super(props);

    this.state = {
      content: props.isExpanded
    };
  }

  private showContent = (e: any) => {
    this.setState({
      content: !this.state.content
    });
  };

  public componentDidMount() { }

  public render(): React.ReactElement<IAccordionProps> {
    return (
      <>
        <Stack horizontal={false} styles={this.accordion}>
          <Stack.Item styles={this.accordionHeader}>
            <Stack horizontal={true} >
              <Stack.Item onClick={this.showContent}>
                <IconButton
                  iconProps={{
                    iconName: this.state.content
                      ? "ChevronDown"
                      : "ChevronRight"
                  }}
                />
              </Stack.Item>
              <Stack.Item grow onClick={this.showContent} align="center">{this.props.header}</Stack.Item>
              {this.props.actions && <Stack.Item align="end">{this.props.actions}</Stack.Item>}
            </Stack>
          </Stack.Item>
          {this.state.content && (
            <Stack.Item
              className={AnimationClassNames.slideDownIn20}
              styles={this.accordionContent}
            >
              {this.props.children}
            </Stack.Item>
          )}
        </Stack>
      </>
    );
  }
}

