
import { IDropdownOption } from "@fluentui/react";
import { atom } from "recoil";
import { ISelectList } from "../models/ISelectList";


  export const globalSelectListsState = atom({
    key: 'selectListsState', // unique ID (with respect to other atoms/selectors)
    default:[] as ISelectList [] // default value (aka initial value)
  });
