import { FormEvent, useEffect, useState } from "react";
import {
  DefaultPalette,
  IDropdownOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers, yupToFormErrors, FieldProps, useFormikContext, validateYupSchema, useField } from "formik";

import { FormMode, PanelActionOwner, PanelActionType } from "../../../enums";
import { FormikTextField } from "../../formik-fluent-ui-react/FormikTextField";
import { useAuth0 } from "@auth0/auth0-react";
import useApi from "../../../hooks/useApi";
import { IReportTemplate } from "../../../models/IReportTemplate";
import { IItem } from "../../../models/IItem";
import { Accordion } from "../../General/Accordion";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../../atoms/GlobalMessage";
import { FormikDropdown } from "../../formik-fluent-ui-react/FormikDropdown";
import { FormikDatePicker } from "../../formik-fluent-ui-react/FormikDatePicker";
import { errorMessageStyles } from "../../../styles";
import { ITEMTYPE_FIELD, ITEMTYPE_LOGIC_FIELD, ITEMTYPE_PAGE, ITEMTYPE_SECTION } from "../../../constants/itemTypes";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { ISelectList, ISelectListItem } from "../../../models/ISelectList";
import { globalSelectListsState } from "../../../atoms/SelectListsState";
import { FormikToggle } from "../../formik-fluent-ui-react/FormikToggle";
import { fieldTypeOptions, logicOperators, logicOutcomes } from "../../../constants/dropDownOptions";
import { schemaTemplateItem } from "../../../validation/schemaTemplateItem";
import { getSelectItems } from "../../General/RevizioField";
import { FIELDTYPE_BOOLEAN, FIELDTYPE_NUMBER, FIELDTYPE_SELECT, FIELDTYPE_TEXT } from "../../../constants/fieldTypes";



export interface IReportTemplateSectionEditProps {
  parentTemplateItem?: IItem
  isOpen: boolean;
  dismissFunction: any;
  successFunction: any;
  formMode: FormMode;
  templateId: string;
  templateItem?: IItem;
  itemType: string;

}
export interface IFieldProperties {
  boolean_true_label?: string;
  boolean_false_label?: string;
  number_min?: number;
  number_max?: number;
  date_min?: Date;
  date_max?: Date;
  select_list_id?: string;
  is_required?: boolean;
  select_is_multi?: boolean,
  text_is_multiline?: boolean,
  select_options?: any
}


export const ReportTemplateItemEditPanel = (props: IReportTemplateSectionEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [templateParentItem, setTemplateParentItem] = useState<IItem>();
  const [, setGlobalMessage] = useRecoilState(globalMessageState)
  const [selectLists, setSelectLists] = useRecoilState(globalSelectListsState)
  const [selectListsAsOptions, setSelectListsAsOptions] = useState<IDropdownOption[]>([])
  


  const api = useApi();

  let initialValues: IItem = props.formMode === FormMode.Add
    ? {
      label: "",
      description: "",
      allow_attachments: false,
      allow_notes: false,
      allow_actions: false,
      
      item_type: props.itemType,
      field_type: props.itemType === ITEMTYPE_FIELD ? 'text' : undefined,
      field_properties: {

      }

    }
    : {
      label: props.templateItem ? props.templateItem.label : "",
      item_type: props.templateItem ? props.templateItem.item_type : 'section',
      is_repeatable_section: props.templateItem?.is_repeatable_section,
      is_required_section: props.templateItem?.is_required_section,
      description: props.templateItem ? props.templateItem.description : "",
      allow_attachments: props.templateItem ? props.templateItem.allow_attachments : false,
      allow_notes: props.templateItem ? props.templateItem.allow_notes : false,
      allow_actions: props.templateItem ? props.templateItem.allow_actions : false,
      field_type: props.templateItem ? props.templateItem.field_type : undefined,
      field_properties: props.templateItem ? {
        date_min: props.templateItem.field_properties?.date_min ? new Date(props.templateItem.field_properties?.date_min) : undefined,
        date_max: props.templateItem.field_properties?.date_max ? new Date(props.templateItem.field_properties?.date_max) : undefined,
        boolean_false_label: props.templateItem.field_properties?.boolean_false_label,
        boolean_true_label: props.templateItem.field_properties?.boolean_true_label,
        number_min: props.templateItem.field_properties?.number_min,
        number_max: props.templateItem.field_properties?.number_max,
        select_list_id: props.templateItem.field_properties?.select_list_id,
        is_required: props.templateItem.field_properties?.is_required,
        select_is_multi: props.templateItem.field_properties?.select_is_multi,
        text_is_multiline: props.templateItem.field_properties?.text_is_multiline



      } : {},
      logic_eval_value: props.templateItem ? props.templateItem.logic_eval_value : undefined,
      logic_operator: props.templateItem ? props.templateItem.logic_operator : undefined,
      logic_outcome: props.templateItem ? props.templateItem.logic_outcome : undefined
    }




  useEffect(() => {

    let oDropDownOptions: IDropdownOption[] = [];
    selectLists.map(o => {
      oDropDownOptions.push({
        key: o.list_id,
        text: o.name
      })
    })

    setSelectListsAsOptions(oDropDownOptions);

  }, [])
  // Set initial Open Value, changes on Props
  useEffect(() => {



    // And open if we've been told to
    setisopen(props.isOpen);

    if (props.isOpen) {
      getParentItem();
    }


  }, [props]);

  const getParentItem = async () => {

    const id = props.formMode === FormMode.Add ? props.templateItem?.item_id : props.templateItem?.parent_item_id

    
    if (id) {
      try {

        let result = await api.get(`/templates/${props.templateId}/items/${id}`)
        
        setTemplateParentItem(result.data)

      } catch (error) {
        console.error('Error', error)
  
        setGlobalMessage({
          action: PanelActionType.Error,
          owner: PanelActionOwner.ReportTemplateItem,
          message: `Error Getting Template item`
        });
  
      }
  
  }
}

  const onSubmit = async (
    values: IItem
  ) => {
    // values has the values
    //   console.log('Values',values);


    try {



      // Need to convert date formats to UTC

      // So check field properties and if exists, convert and then send

      let jsonAdd: IItem = {
        item_type: values.item_type,
        description: values.description

      }

      switch (values.item_type) {
        case ITEMTYPE_PAGE: {
          jsonAdd = { ...jsonAdd, label: values.label }
          break;
        }
        case ITEMTYPE_SECTION: {
          jsonAdd = { ...jsonAdd,  label: values.label, is_repeatable_section: values.is_repeatable_section, is_required_section: values.is_required_section }
          break;
        }
        case ITEMTYPE_FIELD: {
          jsonAdd = { ...jsonAdd, allow_actions: values.allow_actions, allow_attachments: values.allow_attachments, allow_notes:values.allow_notes, label: values.label, field_type: values.field_type, field_properties: values.field_properties }
          break;
        }
        case ITEMTYPE_LOGIC_FIELD: {
          jsonAdd = { ...jsonAdd, logic_operator: values.logic_operator, logic_outcome: values.logic_outcome, logic_eval_value: values.logic_eval_value }
          break;
        }

      }

      // Add Parent ID

      if (props.parentTemplateItem && props.formMode === FormMode.Add) {
        // Spread operator will add parent
        jsonAdd = { ...jsonAdd, parent_item_id: props.parentTemplateItem?.item_id }
      }



      let result = props.formMode === FormMode.Add ? await api.post(`/templates/${props.templateId}/items`,

        jsonAdd



      ) : await api.put(`/templates/${props.templateId}/items/${props.templateItem?.item_id}`,
        jsonAdd
      )

      setGlobalMessage({
        action: PanelActionType.Success,
        owner: PanelActionOwner.ReportTemplateItem,
        message: "Template Item Create/Update Success"
      });

      props.successFunction(result.data);

    } catch (error) {
      console.error('Error', error)

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.ReportTemplateItem,
        message: `Error Creating/Updating Template item`
      });

    }
  }

  if (!props.templateItem && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }




  return (
    <Panel
      headerText={
        `${props.formMode === FormMode.Add ? 'New' : 'Edit'} ${props.itemType}`
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      overlayProps={{ isDarkThemed: true }}

    >

      <Formik
        enableReinitialize
        initialValues={
          initialValues

        }
        validationSchema={schemaTemplateItem}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isValid, values }) => (
          <Form>
            <Stack styles={{
              root: {
                marginBottom: 12
              },
            }}>


              {
                props.itemType != ITEMTYPE_LOGIC_FIELD &&
                <>
                  <Label htmlFor="name">Label</Label>
                  <Field name="label" id="label" component={FormikTextField} />
                </>
              }
              {
                <>
                  <Label htmlFor="description">Description</Label>
                  <Field name="description" id="description" rows={5} multiline component={FormikTextField} />
                </>
              }
              {
                props.itemType === ITEMTYPE_SECTION &&
                <>

                  <Field label="Repeatable?" name="is_repeatable_section" id="is_repeatable_section" component={FormikToggle} />
                  <Field label="Required Section?" name="is_required_section" id="is_required_section" component={FormikToggle} />

                </>
              }
              {props.itemType === ITEMTYPE_FIELD &&
                <>
                  <Label htmlFor="field_type">Field Type</Label>
                  <Field name="field_type" id="field_type">{(fieldProps: FieldProps<any>) => (
                    <FormikDropdown disabled={props.formMode === FormMode.Edit} {...fieldProps} options={fieldTypeOptions} />)}</Field>
                  <Field label="Is Required?" name="field_properties.is_required" component={FormikToggle} />
                  <Field label="Allow Notes?" name="allow_notes" component={FormikToggle} />
                  <Field label="Allow Attachments?" name="allow_attachments" component={FormikToggle} />
                  <Field label="Allow Actions?" name="allow_actions" component={FormikToggle} />
                

                  {values.field_type === 'text' &&
                    <>
                      <Field label='Is Multi Line?' name='field_properties.text_is_multiline' id='field_properties.text_is_multiline' component={FormikToggle} />
                    </>}
                  {values.field_type === 'boolean' &&
                    <>
                      <Label htmlFor="field_properties.boolean_true_label">'Yes' text</Label>
                      <Field name='field_properties.boolean_true_label' id='FieldProperties.boolean_true_label' component={FormikTextField} />

                      <Label htmlFor="field_properties.boolean_false_label">'No' text</Label>
                      <Field name='field_properties.boolean_false_label' id='FieldProperties.boolean_false_label' component={FormikTextField} />
                    </>}
                  {values.field_type === 'number' &&
                    <>
                      <Label htmlFor="field_properties.number_min">MIN</Label>
                      <Field name='field_properties.number_min' id='FieldProperties.number_min' component={FormikTextField} />
                      <Label htmlFor="field_properties.number_max">MAX</Label>
                      <Field name='field_properties.number_max' id='FieldProperties.number_max' component={FormikTextField} />
                    </>}
                  {values.field_type === 'datetime' &&
                    <>
                      <Label htmlFor="field_properties.date_min">MIN</Label>
                      <Field isRequired name='field_properties.date_min' id='FieldProperties.date_min' component={FormikDatePicker} />
                      <ErrorMessage name='field_properties.date_min'>{msg => <Text
                        style={errorMessageStyles}>{msg}</Text>}</ErrorMessage>
                      <Label htmlFor="field_properties.date_max">MAX</Label>
                      <Field isRequired name='field_properties.date_max' id='FieldProperties.date_max' component={FormikDatePicker} />
                      <ErrorMessage name='field_properties.date_max'>{msg => <Text
                        style={errorMessageStyles}>{msg}</Text>}</ErrorMessage>
                    </>}
                  {values.field_type === 'select' &&
                    <>
                      <Label htmlFor="field_properties.select_list_id">Select List</Label>
                      <Field name='field_properties.select_list_id' id='field_properties.select_list_id' component={FormikDropdown} options={selectListsAsOptions} />
                      <Field label='Is Multi Select?' name='field_properties.select_is_multi' id='field_properties.select_is_multi' component={FormikToggle} />

                    </>}
                </>
              }
              {props.itemType === ITEMTYPE_LOGIC_FIELD &&
                <>
                  {templateParentItem && <Label>Logic for '{templateParentItem.label}'</Label>}
                  <Label htmlFor="logic_operator">IF '{templateParentItem?.label}' is</Label>
                  <Field name='logic_operator' id='logic_operator' component={FormikDropdown} options={logicOperators} />
                  <Label htmlFor="logic_eval_value">to the value</Label>
                  
                  { /*templateParentItem?.field_type === FIELDTYPE_SELECT && templateParentItem?.field_properties?.select_list_id && <Field name='logic_eval_value' id='logic_eval_value' options={getSelectItems(selectLists,templateParentItem?.field_properties?.select_list_id)} component={FormikDropdown} /> */  }
                  
                  { /*templateParentItem?.field_type != FIELDTYPE_SELECT &&  <Field name='logic_eval_value' id='logic_eval_value' component={FormikTextField} /> */}
                    { <Field name='logic_eval_value' id='logic_eval_value' component={FormikTextField} />}
                  <Label htmlFor="logic_outcome">then do the following</Label>
                  <Field name='logic_outcome' id='logic_outcome' component={FormikDropdown} options={logicOutcomes} />
                </>}
            </Stack>

            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit" disabled={!isValid}>Submit</PrimaryButton>
            </Stack>

          </Form>
        )}
      </Formik>

{/*}
      <Accordion header='Debug' color={DefaultPalette.red}>
        <pre>{JSON.stringify(templateParentItem, null, 2)}</pre>
              </Accordion>*/}

    </Panel>
  );
};


