import { ISelectList, ISelectListItem } from "../../models/ISelectList";
import useApi from "../../hooks/useApi";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { result } from "lodash";
import { PanelActionType, PanelActionOwner, FormMode } from "../../enums";
import { DetailsRow, Dropdown, getTheme, IconButton, IDetailsListProps, IDetailsRowStyles, Label, Stack, StackItem, Text, TooltipHost } from "@fluentui/react";
import { DataTable } from "../General/DataTable";
import { usePersistedState } from "../../hooks/usePersistedState";
import customer from "../../pages/customer";
import { ISite } from "../Customer/CustomerContentEditPanel";
import { useId } from "@fluentui/react-hooks";
import useStateIfMounted from "../../hooks/useStateIfMounted";
import { SelectListEditPanel } from "./SelectListEditPanel";
import { IProduct } from "../../interfaces/IProduct";
import { ProductEditPanel } from "./ProductEditPanel";
import { globalProductsState } from "../../atoms/ProductsState";


const Products = () => {

    const api = useApi();

    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    // Paging
    const [pageSize, setPageSize] = usePersistedState('ProductsPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('ProductsCurrentPage', 1);

    // Searching
    const [searchTerm, setSearchTerm] = useState("");

    const [currentProduct, setCurrentProduct] = useState<IProduct>();

    const [products,setProducts] = useState<IProduct []>([])
    const [totalProducts, setTotalProducts] = useStateIfMounted<number>(0);

    const [globalProducts, setGlobalProducts] = useRecoilState(globalProductsState)
    const detailTooltipId = useId('detailTooltip');


    const [isEditProductOpen, setIsEditProductOpen] = useState(false);


    const getPagedProducts = async () => {

     

        try {


            const result = await api.get(`/configuration/products?orderBy=name&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`);

            //console.log('Products',result.data)

            setProducts(result.data.data);
            setTotalProducts(result.data.count)
            


        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Products`
            });

        }

    }


    useEffect(() => {

        getPagedProducts();

    }, [currentPage, searchTerm, pageSize])



    return (<><DataTable
    newButtonText="New Product"
        pageSize={pageSize}

        columns={
            [

                {
                    key: 'name',
                    name: 'Product Name',
                    fieldName: 'name',
                    minWidth: 100,
                    maxWidth: 400
                },
                {
                    key: 'code',
                    name: 'Code',
                    fieldName: 'code',
                    minWidth: 100,
                    maxWidth: 400
                },
                {
                    key: 'unit_price',
                    name: 'Unit Price',
                    fieldName: 'unit_price',
                    minWidth: 100,
                    maxWidth: 400
                },
                {
                    key: 'currency',
                    name: 'Currency',
                    fieldName: 'currency',
                    minWidth: 100,
                    maxWidth: 400
                },


                
                {

                    key: 'actions',
                    name: 'Actions',
                    minWidth: 100,
                    isResizable: false,
                    onRender: (item) => (
                        <Stack horizontal>




                            <TooltipHost
                                content="Edit Select List"
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                id={detailTooltipId}
                            >

                                <IconButton aria-describedby={detailTooltipId} iconProps={{ iconName: 'Edit' }} onClick={() => {  setIsEditProductOpen(true); setCurrentProduct(item) }
                                } />
                            </TooltipHost>




                        </Stack>
                    ),
                },
            ]
        }
        items={products}
        totalItems={totalProducts}
        onNewItemClick={() => {
            setIsEditProductOpen(true)
        }}
        onItemClicked={(item: ISite) => {

        }}
        currentPage={currentPage}
        onPaginationClick={(page: number) => {
            setCurrentPage(page);
        }}
        onSearchTextChange={(text: string) => {
            setCurrentPage(1);
            setSearchTerm(text);
        }}
        onPageSizeChange={(size: number) => {
            setPageSize(size);
        }} />
        <ProductEditPanel formMode={currentProduct ? FormMode.Edit : FormMode.Add} isOpen={isEditProductOpen} product_id={currentProduct ? currentProduct.product_id : ""} dismissFunction={() => {setIsEditProductOpen(false); setCurrentProduct(undefined)}} successFunction={() => {
            setCurrentProduct(undefined);
            setIsEditProductOpen(false);
            getPagedProducts();
        }} /></>)

  
}

        export default Products;