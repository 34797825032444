import { Text, ChoiceGroup, DefaultButton, DetailsList, IconButton, Label, Panel, PanelType, PrimaryButton, SelectionMode, Stack, TextField, TooltipHost, initializeResponsiveMode, ActionButton, Separator } from "@fluentui/react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import { boolean } from "yup"
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { IItem } from "../../models/IItem";
import { FormikDropdown } from "../formik-fluent-ui-react/FormikDropdown";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";
import * as Yup from "yup";
import { useId } from "@fluentui/react-hooks";
import useApi from "../../hooks/useApi";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { FormikChoiceGroup } from "../formik-fluent-ui-react/FormikChoiceGroup";
import { globalProductsState } from "../../atoms/ProductsState";


export interface IAction {
    action_id?: string;
    description: string;
    priority: string;
    line_items: IActionLineItem[]
}

interface IActionEditPanelProps {
    isOpen: boolean;
    dismissFunction: any;
    successFunction: any;
    action?: IAction;
    item: IItem;
    report_id: string;
    formMode: FormMode
}

interface IActionPost {
    description: string;
    priority: string;
    line_items: IActionLineItem[]
}

export interface IActionLineItem {
    item_code: string;
    description: string;
    quantity: number;
}
interface IActionFormValues {
    description: string;
    priority: string;
    line_items: IActionLineItem[]
}


const schemaAction = Yup.object().shape({
    description: Yup.string().required("You must complete the description"),
    priority: Yup.string().required("You must enter a priority"),
    line_items: Yup.array().of(
        Yup.object({
            item_code: Yup.string().required("You nust enter an item code"),
            description: Yup.string().required("You must enter a description for this item"),
            quantity: Yup.number().integer("You must enter an integer")
        }).optional())
});


export const ActionEditPanel = (props: IActionEditPanelProps) => {


    const [isopen, setIsOpen] = useState(false);

    const detailTooltipId = useId('detailTooltip');

    const api = useApi();
    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    const [products, setProducts] = useRecoilState(globalProductsState)



    useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props])


    return (<Panel type={PanelType.large} isOpen={props.isOpen} onDismiss={() => props.dismissFunction()} headerText={'Manage Actions'}>



        <Stack>
            {props.action && props.action.description}

            <Formik

                onSubmit={async (values: IActionFormValues) => {


                    // At this stage we need to add or update action to item, and when successful return


                    try {

                        let jsonAdd: IActionPost = {
                            description: values.description,
                            priority: values.priority,
                            line_items: values.line_items
                        }


                        let result =
                            props.formMode === FormMode.Edit
                                ? await api.put(`/reports/${props.report_id}/items/${props.item.item_id}/actions/${props.action?.action_id}`, jsonAdd)
                                : await api.post(`/reports/${props.report_id}/items/${props.item.item_id}/actions`, jsonAdd);

                        setGlobalMessage({
                            action: PanelActionType.Success,
                            owner: PanelActionOwner.Report,
                            message: "Action Create/Update Success"
                        });

                        props.successFunction();

                    } catch (error) {
                        console.log('Error', error)

                        setGlobalMessage({
                            action: PanelActionType.Error,
                            owner: PanelActionOwner.Customer,
                            message: `Error Creating/Updating Action ${values.description}`
                        });

                    }



                    // At this point we need to reload the report on return

                }}
                enableReinitialize
                initialValues={
                    props.formMode === FormMode.Add
                        ? {
                            description: '',
                            priority: 'Low',
                            line_items: []

                        }
                        : {
                            description: props.action ? props.action.description : "",
                            priority: props.action ? props.action.priority : "",
                            line_items: props.action ? props.action.line_items : []
                        }
                }
                validationSchema={schemaAction}
            >{({ isValid, values, validateField, setFieldValue }) => (

                <Form>
                    <Stack styles={{
                        root: {
                            marginBottom: 12
                        },
                    }}>
                        <Field label="Description" name="description" type="text" component={FormikTextField} />
                        <Field label="Priority" name="priority" type="text" component={FormikChoiceGroup} options={[{
                            key: 'Low',
                            text: 'Low'
                        }, {
                            key: 'Medium',
                            text: 'Medium'
                        }, {
                            key: 'High',
                            text: 'High'
                        }

                        ]} />
                        <FieldArray name="line_items">
                            {({ insert, remove, push }) => (
                                <>
                                    <Separator />
                                    <Label>Line Items:</Label>
                                    <Separator />
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Item Code</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.line_items && values.line_items.length > 0 &&
                                                values.line_items.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><Field name={`line_items.${index}.item_code`} onChanged={(event: any) => { setFieldValue(`line_items.${index}.description`, event.text) }} component={FormikDropdown} options={products.map((product, index) => {
                                                            return {
                                                                key: product.code,
                                                                text: `${product.code} ${product.name}`
                                                            }
                                                        })} style={{ minWidth: '250px', maxWidth: '300px' }} /></td>

                                                        <td><Field name={`line_items.${index}.description`} style={{ width: '400px' }} component={FormikTextField} /></td>
                                                        <td><Field name={`line_items.${index}.quantity`} component={FormikTextField} /></td>
                                                        <td> <TooltipHost
                                                            content={`Delete '${item.description}'`}
                                                            // This id is used on the tooltip itself, not the host
                                                            // (so an element with this id only exists when the tooltip is shown)
                                                            id={`${detailTooltipId}-${index}`}
                                                        >

                                                            <IconButton aria-describedby={`${detailTooltipId}-${index}`} iconProps={{ iconName: 'delete' }} onClick={() => { remove(index) }
                                                            } />
                                                        </TooltipHost>
                                                        </td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>

                                    </table>
                                    <Stack horizontal horizontalAlign="center" tokens={{ padding:10}}>
                                        <PrimaryButton aria-describedby={`${detailTooltipId}-addlineitem`} iconProps={{ iconName: 'add' }} onClick={() => { push({ item_code: '', description: '', quantity: 0 }) }
                                        }>Add Item</PrimaryButton>
                                    </Stack>



                                </>
                            )}
                        </FieldArray>
                    </Stack>
                    <Stack horizontal>

                        <PrimaryButton type="submit" styles={{ root: { marginRight: 8 } }} disabled={!isValid}>{`${props.formMode === FormMode.Edit ? 'Save' : 'Submit'}`} </PrimaryButton>
                        <DefaultButton onClick={props.dismissFunction} text="Cancel" />
                    </Stack>
                </Form>
            )}

            </Formik>

        </Stack>


    </Panel>
    )
}

export default ActionEditPanel