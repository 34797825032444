import { useEffect, useState } from "react";
import {
  DefaultButton,
  DefaultPalette,
  IconButton,
  IDropdownOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  sizeToPixels,
  Spinner,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { Formik, Form, Field, FormikHelpers, yupToFormErrors, validateYupSchema, FieldArray } from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";

import useApi from "../../hooks/useApi";
import { useRecoilState } from "recoil";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { Accordion } from "../General/Accordion";
import { ISelectList, ISelectListItem } from "../../models/ISelectList";

import { useId } from "@fluentui/react-hooks";

export interface ISelectListEditProps {


  list_id?: string;
  isOpen: boolean;
  dismissFunction: any;
  successFunction: any;
  formMode: FormMode

}


const schemaSelectList = Yup.object().shape({
  name: Yup.string().required("You must enter a Select List name"),
  items: Yup.array().of(
    Yup.object({
      display: Yup.string().required("You nust enter a display value"),
      value: Yup.string().required("You must enter a value for this item"),
      order: Yup.number().integer("You must enter an integer")
    }).optional()
  )


});

interface ISelectListFormValues {
  name: string;
  items: ISelectListItem[];
}

interface ISelectListPost {
  name: string;
  items: ISelectListItem[];
}

export const SelectListEditPanel = (props: ISelectListEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [selectList, setSelectList] = useState<ISelectList>();
  const [, setGlobalMessage] = useRecoilState(globalMessageState)
  const detailTooltipId = useId('detailTooltip');


  const api = useApi();

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getSelectList();
  }, [props]);



  const getSelectList = async () => {

    // Use the Customer ID from properties
    try {


      const result = await api.get(`/configuration/lists/${props.list_id}`);

      setSelectList(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Select List ${props.list_id}`
      });

    }
  };

  if (!selectList && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  return (
    <Panel
      headerText={
        props.formMode === FormMode.Add ? "New Select List" : "Edit Select List"
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.large}
      overlayProps={{ isDarkThemed: true }}

    >
      <Label>{selectList?.list_id}</Label>
      <Formik
        enableReinitialize
        initialValues={
          props.formMode === FormMode.Add
            ? {
              name: "",
              items: []



            }
            : {
              name: selectList ? selectList.name : "",
              items: selectList ? selectList.items : []


            }
        }
        validationSchema={schemaSelectList}
        onSubmit={async (
          values: ISelectListFormValues,
          { setSubmitting }: FormikHelpers<ISelectListFormValues>
        ) => {
          // values has the values
          // console.log(values);


          try {

            let jsonAdd: ISelectListPost = {
              name: values.name,
              items: values.items
            }


            let result =
              props.formMode === FormMode.Edit
                ? await api.put(`/configuration/lists/${props.list_id}`, jsonAdd)
                : await api.post(`/configuration/lists`, jsonAdd);

            setGlobalMessage({
              action: PanelActionType.Success,
              owner: PanelActionOwner.Report,
              message: "Select List Create/Update Success"
            });

            props.successFunction();

          } catch (error) {
            console.log('Error', error)

            setGlobalMessage({
              action: PanelActionType.Error,
              owner: PanelActionOwner.Customer,
              message: `Error Creating/Updating Report ${selectList?.name}`
            });

          }
        }}
      >
        {({ errors, touched, isValid, values }) => (
          <Form>
            <Stack styles={{
              root: {
                marginBottom: 12
              },
            }}>
              <Label htmlFor="name">Select List Name</Label>
              <Field name="name" id="name" component={FormikTextField} />
              <Label htmlFor="items">Items</Label>
              <FieldArray name="items">
                {({ insert, remove, push }) => (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: '50%'}}>Display</th>
                          <th>Value</th>
                          <th>Order</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.items.length > 0 &&
                          values.items.map((item, index) => (
                            <tr key={index}>
                              <td><Field name={`items.${index}.display`} component={FormikTextField} /></td>
                              <td><Field name={`items.${index}.value`} component={FormikTextField} /></td>
                              <td><Field name={`items.${index}.order`} component={FormikTextField} /></td>
                              <td> <TooltipHost
                                content={`Delete '${item.display}'`}
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                id={`${detailTooltipId}-${index}`}
                              >

                                <IconButton aria-describedby={`${detailTooltipId}-${index}`} iconProps={{ iconName: 'delete' }} onClick={() => { remove(index) }
                                } />
                              </TooltipHost>
                              </td>
                            </tr>
                          ))
                        }
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <TooltipHost
                              content={`Add Item`}
                              // This id is used on the tooltip itself, not the host
                              // (so an element with this id only exists when the tooltip is shown)
                              id={`${detailTooltipId}-add`}
                            >

                              <IconButton aria-describedby={`${detailTooltipId}-add`} iconProps={{ iconName: 'add' }} onClick={() => { push({ display: '', value: '', order: 0 }) }
                              } />
                            </TooltipHost> 


                            
                          </td>
                        </tr>
                      </tbody>

                    </table>

                  </>
                )}
              </FieldArray>

            </Stack>
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit" styles={{ root: { marginRight: 8 } }} disabled={!isValid}>{`${props.formMode === FormMode.Edit ? 'Save' : 'Submit'}`} </PrimaryButton>
              <DefaultButton onClick={props.dismissFunction} text="Cancel" />
            </Stack>
          </Form>
        )}
      </Formik>

                              {/*
      <Accordion header='Debug' color={DefaultPalette.red}>
        <pre>{JSON.stringify(selectList, null, 2)}</pre>
                            </Accordion>*/}
    </Panel>
  );
};


