import { AnimationClassNames, DefaultPalette, IconButton, IStackItemStyles, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

interface IDisplayBlockProps {
  header: any;
  color?: string;
  children: any;
}

export class DisplayBlock extends React.Component<IDisplayBlockProps, {}> {


  

  private accordionHeader: IStackItemStyles = {
    root: {
      background: DefaultPalette.neutralLighter,
      padding: 5,
      cursor: "pointer",
      borderLeftStyle: "solid",
      borderLeftColor: this.props.color,
      borderLeftWidth: 5

    }
  };

  private accordion: IStackStyles = {
    root: {
      borderWidth: 1,
      marginTop: 10,
      marginLeft: 10,
      marginRight: 2,
      marginBottom: 2,
      borderColor: this.props.color,
      borderStyle: "solid"


    }
  };

  private accordionContent: IStackStyles = {
    root: {
      borderWidth: 1,
      padding: 10,
      borderColor: this.props.color
    }
  };

  constructor(props: IDisplayBlockProps, public state: any) {
    super(props);

    

    
  }

  private showContent = (e: any) => {
    this.setState({
      content: !this.state.content
    });
  };

  public componentDidMount() { }

  public render(): React.ReactElement<IDisplayBlockProps> {
    return (
      <>
        <Stack horizontal={false} styles={this.accordion}>
          <Stack.Item styles={this.accordionHeader}>
            <Stack horizontal={true} >
              
          
              <Stack.Item grow align="center">{this.props.header}</Stack.Item>
              
            </Stack>
          </Stack.Item>
          
            <Stack.Item
              className={AnimationClassNames.slideDownIn20}
              styles={this.accordionContent}
            >
              {this.props.children}
            </Stack.Item>
         
        </Stack>
      </>
    );
  }
}

