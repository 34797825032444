import { DefaultPalette, IMessageBarStyles, IStackItemStyles, IStackStyles, mergeStyles } from "@fluentui/react";

export const messageBarStyles: IMessageBarStyles = {
    content: mergeStyles({
      alignItems: "center",
    }),
    text: mergeStyles({
      fontSize: 12,
    }),
    icon: mergeStyles({
      fontSize: 16,
    }),
  };

export const pageContainerStyles = { padding: "30px" }

export const pageHeaderStyles : IStackStyles = {
    root: {
       paddingLeft: "30px",
       paddingTop: "8px",
       paddingBottom: "8px",
       background: DefaultPalette.neutralLighter,

       
      }
}

export const errorMessageStyles = {
  color: DefaultPalette.red,
  fontSize: '12px'
}