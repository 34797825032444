import { useEffect, useState } from "react";
import {
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { Formik, Form, Field, FormikHelpers, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { FormMode, PanelActionOwner, PanelActionType } from "../../enums";
import { FormikTextField } from "../formik-fluent-ui-react/FormikTextField";
import { useAuth0 } from "@auth0/auth0-react";
import useApi from "../../hooks/useApi";
import { IReportTemplate } from "../../models/IReportTemplate";
import { IReportTemplateEditProps } from "./ReportTemplateContentEditPanel";
import { globalMessageState } from "../../atoms/GlobalMessage";
import { useRecoilState } from "recoil";

const schemaCustomer = Yup.object().shape({
  name: Yup.string().required("You must enter a customer name")

});

interface IReportTemplateFormValues {
  name: string;

}



export const ReportTemplateEditPanel = (props: IReportTemplateEditProps) => {
  const [isopen, setisopen] = useState(false);
  const [template, setTemplate] = useState<IReportTemplate>();
  const { user, getAccessTokenSilently } = useAuth0();
  const [,setGlobalMessage] = useRecoilState(globalMessageState);

  const api = useApi();

  // Set initial Open Value, changes on Props
  useEffect(() => {
    // And open if we've been told to
    setisopen(props.isOpen);

    props.isOpen && props.formMode === FormMode.Edit && getReportTemplate();
  }, [props]);

  const getReportTemplate = async () => {

    // Use the Customer ID from properties
    try {


      const result = await api.get(`/templates/${props.template_id}`);

      setTemplate(result.data);
    } catch (e) {
      console.error(e);

      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Customer,
        message: `Error Getting Template ${props.template_id}`
      });

    }
  };

  if (!template && props.formMode === FormMode.Edit && isopen) {
    return <Spinner></Spinner>;
  }

  return (
    <Panel
      headerText={
        props.formMode === FormMode.Add ? "New Template" : "Edit Template Details"
      }
      isOpen={isopen}
      onDismiss={() => {
        props.dismissFunction();
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.smallFixedFar}
      overlayProps={{ isDarkThemed: true }}

    >
      <Formik
        enableReinitialize
        initialValues={
          props.formMode === FormMode.Add
            ? {
              name: "",

            }
            : {
              name: template ? template.name : "",

            }
        }
        validationSchema={schemaCustomer}
        onSubmit={async (
          values: IReportTemplateFormValues,
          { setSubmitting }: FormikHelpers<IReportTemplateFormValues>
        ) => {
          // values has the values
          // console.log(values);


          try {
            let result =
              props.formMode === FormMode.Edit
                ? await api.put(`/templates/${props.template_id}`, {
                  name: values.name,

                })
                : await api.post(`/templates`, {
                  name: values.name,

                });

                setGlobalMessage({
                  action: PanelActionType.Success,
                  owner: PanelActionOwner.ReportTemplate,
                  message: "Template Create/Update Success"
                });

            props.successFunction(result.data);

          } catch (error) {
            console.error('Error', error)

            setGlobalMessage({
              action: PanelActionType.Error,
              owner: PanelActionOwner.ReportTemplate,
              message: `Error Creating/Updating Template ${template?.name}`
            });

          }
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <Stack styles={{
              root: {
                marginBottom: 12
              },
            }}>
              <Label htmlFor="name">Template Name</Label>
              <Field name="name" id="name" component={FormikTextField} />
            </Stack>
            <Stack horizontal horizontalAlign="end">
              <PrimaryButton type="submit" disabled={!isValid}>Submit</PrimaryButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Panel>
  );
};


