import { useAuth0 } from "@auth0/auth0-react";
import { DefaultButton } from "@fluentui/react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <DefaultButton
      onClick={() => loginWithRedirect()}
      text="Log In"
      style={{ marginTop: "auto", marginBottom: "auto", marginRight: "4px" }}
    />
  );
};

