
import { Text, Stack } from "@fluentui/react"
import PageTitle from "../components/layout/pageTitle";

const Privacy = () => {


    return (
        <>
            <PageTitle title="Revizio Privacy Policy" />
            

            <Stack tokens={{ childrenGap: 10}}>
            
           
                <Text>Effective date: 24th April 2023</Text>

<Text>We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information when you use our Android app, "Revizio". By using our app, you acknowledge that you have read and understood this Privacy Policy and agree to be bound by its terms. If you do not agree with the practices described in this Privacy Policy, please do not use our app.</Text>
<Text>Information We Collect</Text>
<Text>Personal Information: We do not collect any personal information from users of our app. We do not require you to provide any personal information to use our app, and we do not collect, store, or transmit any personally identifiable information (PII) such as names, addresses, phone numbers, or email addresses.</Text>
<Text>Non-Personal Information: When you use "Revizio", we may collect non-personal information that does not identify you personally, such as usage data, device information, and app performance data. This information may include your device's operating system, app version, device model, language preference, crash logs, and other technical information about how you use our app.</Text>
<Text>Cookies: "Revizio" does not use cookies or similar tracking technologies to collect information.</Text>
<Text>How We Use Your Information</Text>
<Text>We use the information collected from "Revizio" to improve the functionality and performance of our app, analyze usage patterns, and ensure app stability and security. We may also use non-personal information for statistical, analytical, and research purposes to better understand user behavior and preferences.</Text>
<Text>Disclosure of Your Information</Text>
<Text>We do not share, sell, or disclose any information collected from "Revizio" to third parties, except as required by law or to protect our legal rights.</Text>
<Text>Security</Text>

<Text>We take reasonable measures to protect the information collected from "Revizio" against loss, theft, and unauthorized access, disclosure, alteration, or destruction. However, please note that no data transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.</Text>

<Text>Children's Privacy</Text>

<Text>"Revizio" is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected personal information from a child under 13, please contact us immediately, and we will take appropriate steps to remove the information from our records.</Text>

<Text>Updates to Privacy Policy</Text>

<Text>We may update this Privacy Policy from time to time to reflect changes in our privacy practices. We will notify you of any material changes by posting the updated Privacy Policy on this page and updating the "Effective date" above. We encourage you to review this Privacy Policy periodically for any updates.</Text>

<Text>Contact Us</Text>

<Text>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at revizioproject@gmail.com.</Text>

<Text>By using "Revizio", you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</Text>
                

            </Stack>
        </>

    );
};

export default Privacy

