import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { useAuth0 } from "@auth0/auth0-react";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Nav } from "./components/Nav";
import { FullScreenLoading } from "./components/FullScreenLoading";
import Customers from "./pages/customers";
import PageContainer from "./components/layout/pageContainer";
import ReportTemplates from "./pages/reporttemplates";
import Reports from "./pages/reports";
import { Stack, Text } from "@fluentui/react";
import PageHeader from "./components/layout/pageheader";
import Customer from "./pages/customer";
import Report from "./pages/report";
import SelectListEditor from "./pages/selectLists";
import { ProductEditPanel } from "./components/Configuration/ProductEditPanel";
import ProductsListEditor from "./pages/products";
import Privacy from "./pages/privacy";


export const App: React.FC = () => {

  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <FullScreenLoading /> 
      </div>

    );
  }

  return (
    <>
      <PageHeader />
      <Nav />
      <PageContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/customers"
            element={<ProtectedRoute component={Customers} />}
          />
           <Route
            path="/customer/:id"
            element={<ProtectedRoute component={Customer} />}
          />
          <Route
            path="/reporttemplates"
            element={<ProtectedRoute component={ReportTemplates} />}
          />
          <Route
            path="/reports"
            element={<ProtectedRoute component={Reports} />}
          />
           <Route
            path="/report/:id"
            element={<ProtectedRoute component={Report} />}
          />
            <Route
            path="/reports/:id/:siteid"
            element={<ProtectedRoute component={Reports} />}
          />
           <Route
            path="/selectlists"
            element={<ProtectedRoute component={SelectListEditor} />}
          />
           <Route
            path="/products"
            element={<ProtectedRoute component={ProductsListEditor} />}
          />
          <Route
            path="/privacy"
            element={<Privacy />} />
           
       
        </Routes>
      </PageContainer>
      
    </>
    
  );

}


