
import {
    Callout,
    DatePicker,
    DayOfWeek,
    DefaultPalette,
    Dropdown,
    Icon,
    IconButton,
    IDropdownOption,
    Label,
    Separator,
    Stack,
    StackItem,
    Text,
    TextField,
    Toggle,
    TooltipHost,
} from "@fluentui/react";
import { ReactNode, useState } from "react";
import { IItem } from "../../../models/IItem";
import { Accordion } from "../../General/Accordion";
import { AddToolbar } from "../../General/AddToolbar";
import dayjs from 'dayjs';
import { ITEMTYPE_FIELD, ITEMTYPE_SECTION, ITEMTYPE_LOGIC_FIELD, ITEMTYPE_PAGE } from "../../../constants/itemTypes";
import { useRecoilState } from "recoil";
import { globalSelectListsState } from "../../../atoms/SelectListsState";
import { useBoolean, useId } from "@fluentui/react-hooks";


export interface IItemsProps {
    toplevel: boolean;
    name: string;
    items: any[];
    onAdd: any;
    onDelete: any;
    onEdit: any;
    parentLabel?: string;
}

export const ReportTemplateItems = (props: IItemsProps) => {

    const [openEditTemplateItemPanel, setOpenEditTemplateItemPanel] = useState<boolean>(false);
    const [currentTemplateitem, setCurrentTemplateitem] = useState<any>();
    const [selectLists, setSelectLists] = useRecoilState(globalSelectListsState)


    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);


    function getSelectItems(list_id: string): IDropdownOption[] {
        let oDropDownOptions: IDropdownOption[] = [];

        const oSelectListItem = selectLists.filter(o => {
            return o.list_id === list_id;
        })[0];

        oSelectListItem.items.map(o => {
            oDropDownOptions.push({
                key: o.value,
                text: o.display
            })
        })

        return oDropDownOptions.sort((a, b) => {
            return a.text.localeCompare(b.text)
        });
    }

    function getSelectListName(list_id: string): string {
        const oSelectListItem = selectLists.filter(o => {
            return o.list_id === list_id;
        })[0];

        return oSelectListItem.name;
    }

    function renderItem(i: IItem, index: number): ReactNode {


        switch (i.item_type) {

            case ITEMTYPE_PAGE: {
                return (
                    <Stack key={index} tokens={{ 'padding': '10px 0px 0px 0px' }} >
                        <Accordion color={DefaultPalette.themeDark} isExpanded={true} header={<Stack horizontal><Label>PAGE: '{i.label}'</Label></Stack>} actions={
                            <AddToolbar
                                id={i.item_id ? i.item_id : ''}
                                label={i.label ? i.label : ''}
                                displayDeleteButton={(i.items && i.items.length > 0 ? true : false)}
                                onDeleteItemClick={() => { props.onDelete(i); }}
                                onAddSectionClick={() => { props.onAdd(i, ITEMTYPE_SECTION); }}
                                onAddQuestionClick={() => { props.onAdd(i, ITEMTYPE_FIELD); }}
                                onEditPageClick={() => { props.onEdit(i) }}
                                
                                displayEditPageButton={true} />

                        }>

                            <Text>{i.description}</Text>
                            {i.items && <ReportTemplateItems parentLabel={i.label} onEdit={props.onEdit} onDelete={props.onDelete} onAdd={props.onAdd} toplevel={false} name={''} items={i.items} />}

                            <Separator>
                                <TooltipHost
                                    content={`Add Question to '${i.label}'`}
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id={`addquestion`}

                                >
                                    <IconButton aria-describedby="addquestion" iconProps={{ iconName: "AddTo" }} onClick={() => { props.onAdd(i, ITEMTYPE_FIELD); }} />
                                </TooltipHost>
                                <TooltipHost
                                    content={`Add dependent Section to '${i.label}'`}
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id={`addsubsection`}

                                >
                                    <IconButton aria-describedby="addsubsection" iconProps={{ iconName: "DependencyAdd" }} onClick={() => { props.onAdd(i, ITEMTYPE_SECTION); }} />
                                </TooltipHost>

                            </Separator>
                        </Accordion>
                    </Stack>
                )
            }



            case ITEMTYPE_SECTION: {
                return (
                    <Stack key={index} tokens={{ 'padding': '10px 0px 0px 0px' }} >
                        <Accordion color={DefaultPalette.blue} isExpanded={true} header={<Stack horizontal><Label>SECTION: '{i.label}'</Label>{i.is_repeatable_section ? <Icon iconName="RepeatAll" /> : null}{i.is_required_section ? <Icon iconName="FieldRequired" /> : null}</Stack>} actions={
                            <AddToolbar
                                id={i.item_id ? i.item_id : ''}
                                label={i.label ? i.label : ''}
                                displayDeleteButton={(i.items && i.items.length > 0 ? true : false)}
                                onDeleteItemClick={() => { props.onDelete(i); }}
                                onAddSectionClick={() => { props.onAdd(i, ITEMTYPE_SECTION); }}
                                onAddQuestionClick={() => { props.onAdd(i, ITEMTYPE_FIELD); }}
                                onEditSectionClick={() => { props.onEdit(i) }}
                                onEditQuestionClick={undefined}
                                displayEditSectionButton={true} />

                        }>

                            <Text>{i.description}</Text>
                            {i.items && <ReportTemplateItems parentLabel={i.label} onEdit={props.onEdit} onDelete={props.onDelete} onAdd={props.onAdd} toplevel={false} name={''} items={i.items} />}

                            <Separator>
                                <TooltipHost
                                    content={`Add Question to '${i.label}'`}
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id={`addquestion`}

                                >
                                    <IconButton aria-describedby="addquestion" iconProps={{ iconName: "AddTo" }} onClick={() => { props.onAdd(i, ITEMTYPE_FIELD); }} />
                                </TooltipHost>
                                <TooltipHost
                                    content={`Add dependent Section to '${i.label}'`}
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id={`addsubsection`}

                                >
                                    <IconButton aria-describedby="addsubsection" iconProps={{ iconName: "DependencyAdd" }} onClick={() => { props.onAdd(i, ITEMTYPE_SECTION); }} />
                                </TooltipHost>

                            </Separator>
                        </Accordion>
                    </Stack>
                )
            }

            case ITEMTYPE_FIELD: {
                return (
                    <Accordion color={DefaultPalette.green} key={index} isExpanded={false} header={<><label>FIELD:</label>&nbsp;<b>{i.label}</b>&nbsp;{i.field_properties?.is_required ? <Icon iconName="FieldRequired" /> : null}</>} actions={
                        <AddToolbar

                            id={i.item_id ? i.item_id : ''}
                            label={i.label ? i.label : ''}

                            displayEditQuestionButton={true}
                            displayDeleteButton={(i.items && i.items.length > 0 ? true : false)}
                            onDeleteItemClick={() => { props.onDelete(i); }}
                            onEditQuestionClick={() => { props.onEdit(i); }}
                        />
                    }>
                        <Stack>
                            <Text>{i.description}</Text>
                            <Stack horizontal>
                                <Label>Field Type: {i.field_type}</Label>
                                <TooltipHost
                                    content={isCalloutVisible ? 'Hide preview of controls' : 'Show preview of controls'}
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id='field-preview'

                                >
                                    <IconButton aria-describedby='field-preview' iconProps={{ iconName: 'Preview' }} label='Show Preview' onClick={toggleIsCalloutVisible} />

                                </TooltipHost>


                            </Stack>

                            {isCalloutVisible &&
                                <>

                                    {i.field_type === 'text' && <Stack horizontal tokens={{ childrenGap: 10 }} >

                                        <StackItem styles={{ root: { width: '192px' } }}>
                                            {i.field_properties?.text_is_multiline ? <TextField rows={5} multiline /> : <TextField required={i.field_properties?.is_required ? true : false} />}
                                        </StackItem>
                                        <StackItem>

                                        </StackItem>
                                    </Stack>
                                    }
                                    {i.field_type === 'boolean' &&
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                                            <StackItem styles={{ root: { width: '192px' } }}>
                                                <Toggle onText={i.field_properties?.boolean_true_label} offText={i.field_properties?.boolean_false_label} />
                                            </StackItem>
                                            <StackItem >

                                            </StackItem>
                                        </Stack>
                                    }
                                    {i.field_type === 'number' &&


                                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                                            <StackItem styles={{ root: { width: '192px' } }}>
                                                <TextField defaultValue={i.field_properties?.number_min?.toString()} onGetErrorMessage={(value: string): string => {
                                                    try {
                                                        const numberValue = Number.parseFloat(value);

                                                        if (i.field_properties?.number_min && numberValue < i.field_properties.number_min) { return 'Number must be greater or equal than MIN'; }
                                                        if (i.field_properties?.number_max && numberValue > i.field_properties.number_max) { return 'Number must be less or equal to MAX'; }
                                                    } catch (e) {
                                                        return 'Error'
                                                    }

                                                    return '';

                                                }} />
                                            </StackItem>
                                            <StackItem>
                                                <Label>Min: {i.field_properties?.number_min}</Label>
                                                <Label>Max: {i.field_properties?.number_max}</Label>
                                            </StackItem>
                                        </Stack>


                                    }
                                    {i.field_type === 'datetime' &&
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <StackItem styles={{ root: { width: '192px' } }}>
                                                <DatePicker value={i.field_properties?.date_min ? new Date(i.field_properties.date_min) : new Date()} maxDate={i.field_properties?.date_max && new Date(i.field_properties?.date_max)} minDate={i.field_properties?.date_min && new Date(i.field_properties?.date_min)} />
                                            </StackItem>
                                            <Stack horizontal>
                                                <Label>Min: {dayjs(i.field_properties?.date_min).format('DD/MM/YYYY h:mm a')} ,
                                                    Max: {dayjs(i.field_properties?.date_max).format('DD/MM/YYYY h:mm a')}</Label>
                                            </Stack>
                                        </Stack>
                                    }
                                    {(i.field_type === 'select' || i.field_type === 'multi_select') &&
                                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                                            <StackItem styles={{ root: { width: '192px' } }}>
                                                {i.field_properties?.select_list_id && <Dropdown multiSelect={i.field_properties?.select_is_multi} options={getSelectItems(i.field_properties?.select_list_id)} required={i.field_properties?.is_required ? true : false} />}
                                            </StackItem>
                                            <StackItem>
                                                <TooltipHost
                                                    content={`Select List ID '${i.field_properties?.select_list_id}'`}
                                                    // This id is used on the tooltip itself, not the host
                                                    // (so an element with this id only exists when the tooltip is shown)
                                                    id={`selectlistid`}

                                                >
                                                    <Label id={'selectlistid'}>{i.field_properties?.select_list_id ? getSelectListName(i.field_properties?.select_list_id) : null}</Label>

                                                </TooltipHost>




                                            </StackItem>
                                        </Stack>
                                    }
                                </>}



                            <Separator>
                                <TooltipHost
                                    content={`Add logic to Field '${i.label}'`}
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id={`addaddlogic`}

                                ><IconButton aria-describedby="addlogic" iconProps={{ iconName: "DataflowsLink" }} onClick={() => { props.onAdd(i, ITEMTYPE_LOGIC_FIELD); }} />
                                </TooltipHost>
                            </Separator>

                        </Stack>

                        {i.items && <ReportTemplateItems parentLabel={i.label} onDelete={props.onDelete} onAdd={props.onAdd} toplevel={false} name={''} items={i.items} onEdit={props.onEdit} />}



                    </Accordion>
                )

            }

            case ITEMTYPE_LOGIC_FIELD: {
                return (
                    <Accordion color={DefaultPalette.blue} key={index} isExpanded={true} header={<><label>LOGIC:</label>&nbsp;If answer <b>{i.logic_operator}&nbsp;'{i.logic_eval_value}'&nbsp;</b>then<b>&nbsp;{i.logic_outcome}</b></>} actions={
                        <AddToolbar
                            id={i.item_id ? i.item_id : ''}
                            label={i.label ? i.label : ''}
                            displayDeleteButton={(i.items && i.items.length > 0 ? true : false)}
                            displayEditQuestionButton={true}
                            onDeleteItemClick={() => { props.onDelete(i); }}
                            onAddSectionClick={() => { props.onAdd(i, ITEMTYPE_SECTION); }}
                            onAddQuestionClick={() => { props.onAdd(i, ITEMTYPE_FIELD); }}
                            onEditQuestionClick={() => { props.onEdit(i) }}
                        />
                    }>
                        <Text>{i.description}</Text>

                        {i.items && <ReportTemplateItems parentLabel={i.label} onDelete={props.onDelete} onAdd={props.onAdd} toplevel={false} name={''} items={i.items} onEdit={props.onEdit} />}

                        <Separator>
                            <TooltipHost
                                content={`Add question to LOGIC Outcome`}
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                id={`addquestion`}

                            >
                                <IconButton aria-describedby="addquestion" iconProps={{ iconName: "AddTo" }} onClick={() => { props.onAdd(i, ITEMTYPE_FIELD); }} />
                            </TooltipHost>
                        </Separator>


                    </Accordion>
                )

            }
        }

        return <></>




    }



    return (

        <>
            {props.toplevel &&
                <><Text variant="large">{props.name}</Text>



                </>}

            {props.items.map((i: IItem, index: number) => (

                renderItem(i, index)




            ))}
        </>


    )




}





