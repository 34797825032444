
import { Stack, IconButton, TooltipHost } from "@fluentui/react"
import { useId } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { reportTemplateState } from "../atoms/ReportTemplateAtom";
import { DataTable } from "../components/General/DataTable";
import PageTitle from "../components/layout/pageTitle";
import { ReportTemplateContentEditPanel } from "../components/ReportTemplate/ReportTemplateContentEditPanel";
import { ReportTemplateEditPanel } from "../components/ReportTemplate/ReportTemplateEditPanel";
import { FormMode } from "../enums";
import useApi from "../hooks/useApi";
import { usePersistedState } from "../hooks/usePersistedState";
import useStateIfMounted from "../hooks/useStateIfMounted";
import { IReportTemplate } from "../models/IReportTemplate";
import { useAuth0 } from "@auth0/auth0-react";
import { indexOf } from "lodash";



const ReportTemplates = () => {

    const [templates, setTemplates] = useRecoilState(reportTemplateState)
    const [totalTemplates, setTotalTemplates] = useStateIfMounted<number>(0);
    const [currentTemplate, setCurrentTemplate] = useState<IReportTemplate>();


    const [openEditPanel, setOpenEditPanel] = useState<boolean>(false);
    const [openEditContentPanel, setOpenEditContentPanel] = useState<boolean>(false);

    // Paging
    const [pageSize, setPageSize] = usePersistedState('ReportTemplatesPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('ReportTemplatesCurrentPage', 1);

    // Searching
    const [searchTerm, setSearchTerm] = useState("");

    const { user } = useAuth0();

    const detailTooltipId = useId('detailTooltip');

    const api = useApi();


    const fetchReportTemplates = async () => {



        try {
            const result = await api.get(`/templates?orderBy=name&sort=asc&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`);


            setTemplates(result.data.data);
            setTotalTemplates(result.data.count);
        } catch (error) {
            console.error(error)
        }


    };

    useEffect(() => {

        fetchReportTemplates();


    }, [currentPage, searchTerm, pageSize]);



    return (
        <>

            <Stack>

                <PageTitle title="Report Templates" />

                <DataTable
                    newButtonText="New Template"
                    pageSize={pageSize}
                    columns={
                        [

                        {
                            key: 'name',
                            name: 'Name',
                            fieldName: 'name',
                            minWidth: 100,
                            isResizable: true
                        },
                        {
                            key: 'actions',
                            name: 'Actions',
                            isResizable: false,
                            onRender: (item) => (
                                <Stack horizontal>
                                   

                                     <TooltipHost
                                        content="Edit Template Contents"
                                        // This id is used on the tooltip itself, not the host
                                        // (so an element with this id only exists when the tooltip is shown)
                                        id={detailTooltipId}
                                    >
                                    
                                    <IconButton  aria-describedby={detailTooltipId}  iconProps={{ iconName: 'PageheaderEdit' }} onClick={() => { setCurrentTemplate(item); setOpenEditContentPanel(true) }
                                    } />
                                    </TooltipHost>

                                </Stack>
                            ),
                            minWidth: 100
                        }
                        ]
                    }
                    items={templates}
                    totalItems={totalTemplates}
                    onNewItemClick={() => {
                        setCurrentTemplate(undefined); setOpenEditPanel(true)
                    }}
                    onItemClicked={(item: IReportTemplate) => {
                       if(user && user.email?.indexOf('revizio') === -1) {
                        return
                       }
                        setCurrentTemplate(item); setOpenEditContentPanel(true) 
                    }}
                    currentPage={currentPage}
                    onPaginationClick={(page: number) => {
                        setCurrentPage(page);
                    }}
                    onSearchTextChange={(text: string) => {
                        //setCurrentPage(1);
                        setSearchTerm(text);
                    }}
                    onPageSizeChange={(size: number) => {
                        setPageSize(size);
                    }} />

                <ReportTemplateEditPanel formMode={currentTemplate ? FormMode.Edit : FormMode.Add} isOpen={openEditPanel} template_id={currentTemplate ? currentTemplate.template_id : ""} dismissFunction={() => {setOpenEditPanel(false); fetchReportTemplates()}} successFunction={() => {
                    setCurrentTemplate(undefined);
                    setOpenEditPanel(false);
                    fetchReportTemplates();
                }} />

                <ReportTemplateContentEditPanel formMode={currentTemplate ? FormMode.Edit : FormMode.Add} isOpen={openEditContentPanel} template_id={currentTemplate ? currentTemplate.template_id : ""} dismissFunction={() => {setOpenEditContentPanel(false); fetchReportTemplates();}} successFunction={() => {
                    setCurrentTemplate(undefined);
                    setOpenEditContentPanel(false);
                    fetchReportTemplates();
                }} />

            


            </Stack>


        </>

    );
};

export default ReportTemplates

