import { useAuth0 } from "@auth0/auth0-react";
import { Text, Stack } from "@fluentui/react";
import { userInfo } from "os";
import PageTitle from "../components/layout/pageTitle";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useTenantInfo } from "../hooks/useTenantInfo";
import Stats from "../components/General/stats";
import { getTheme } from '@fluentui/react';

const Home = () => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const tenantInfo = useTenantInfo();

  

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const decoded: any = jwtDecode(token);
    });
  }, []);

  return (
    <Stack>
      {isAuthenticated ? (
        <>
          <PageTitle title="Home" />{" "}
          {!tenantInfo?.roles.includes('customer_viewer') && <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="large">
              Welcome '<b>{user && user.name}'</b>
            </Text>
            <Stack>
              <Text>
                To view reports go to the customer or 'Submitted Reports'
              </Text>
              <Text>
                To create a report, go to the Customer, then Site, and create a
                report
              </Text>
              <Text>
                To maintain data used by the system (Select Lists, Templates,
                Products) click the 'Cog' icon
              </Text>
            </Stack>

            <Stats />
          </Stack>}
        </>
      ) : (
        <Stack tokens={{ childrenGap: 10 }}>
          {" "}
          <Text variant="large">
            You are not logged in. Please Log in using the "Log In" button above
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default Home;
