import { getPropsWithDefaults, SearchBox } from "@fluentui/react"
import { useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";


export interface ISearchProps {
    onChange: any
}

export const Search = (props:ISearchProps) => {

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    // Only call onChange on Debounch
  useEffect(
    () => {
     // if (debouncedSearchTerm) {
          if(props.onChange) {
              props.onChange(debouncedSearchTerm);
          }
     // }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
    
    return (
        <>
            <SearchBox placeholder="Enter search term"
                onChange={(event,newValue) => newValue ? setSearchTerm(newValue) : setSearchTerm('')} />
        </>
    )

}
