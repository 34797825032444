import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { CommandBarButton, Stack, IStackStyles, CommandBar, useTheme, Layer } from "@fluentui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from "./auth0/LogoutButton";
import { LoginButton } from "./auth0/LoginButton";
import { ProfileViewPanel } from "./Profile/ProfileViewPanel";
import { useTenantInfo } from "../hooks/useTenantInfo";

const stackStyles: Partial<IStackStyles> = {
  root: { height: 44, backgroundColor: "white" },
};

export const Nav = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const tenantInfo = useTenantInfo();

  const navRoutes = [
    {
      name: "Home",
      path: "/",
      iconName: "GoToDashboard",
    },
    {
      name: "Customers",
      path: "/customers",
      iconName: "People",
    },
    {
      name: "Templates",
      path: "/reporttemplates",
      iconName: "Checkmark",
    },
    {
      name: "Submitted Reports",
      path: "/reports",
      iconName: "ReportWarning",
    }
    


  ];

  /*
  <CommandBarButton
              key={index}
              onClick={() => navigate(route.path)}
              iconProps={{ iconName: route.iconName, style: { fontSize: 20 } }}
              text={route.name}
              checked={location.pathname === route.path}
            />
  */

  const items = [
    {
      key: 'home',
      onClick: () => navigate('/'),
      text: 'Home',
      checked: location.pathname === '/',
      iconProps: { iconName: 'GoToDashboard'}
    },
    {
      key: 'customers',
      onClick: () => navigate('/customers'),
      text: 'Customers',
      checked: location.pathname === '/customers',
      iconProps: { iconName: 'People'}
    },
    {
      key: 'myreports',
      disabled: tenantInfo?.roles.includes('customer_viewer'),
      onClick: () => navigate('/reports'),
      text: 'Review Submitted Reports',
      checked: location.pathname === '/reports',
      iconProps: { iconName: 'ReportWarning'}
    }
  ]




  return (
    <Stack tokens={{ childrenGap: 10}}>

      <div style={{ backgroundColor: theme.palette.greenLight, height: 2}}></div>
      
      {isAuthenticated && 

        <CommandBar items={items} farItems={
          [
            {
              key: 'config',
              checked: location.pathname === '/reporttemplates' || location.pathname === '/selectlists',
              text: 'Config',
              iconProps: { iconName: 'settings'},
              iconOnly: true,
              subMenuProps: { items: [
                {
                  key: 'reporttemplates',
                  onClick: () => navigate('/reporttemplates'),
                  text: 'Report Templates',
                  checked: location.pathname === '/reporttemplates',
                  iconProps: { iconName: 'checkmark'},
                  iconOnly: true,
                  disabled: !tenantInfo?.roles.includes('admin')
                },
                {
                  key: 'selectlists',
                  onClick: () => navigate('/selectlists'),
                  text: 'Select Lists',
                  checked: location.pathname === '/selectlists',
                  iconProps: { iconName: 'bulletedlist'},
                  iconOnly: true,
                  disabled: !tenantInfo?.roles.includes('admin')
                },
                {
                  key: 'products',
                  onClick: () => navigate('/products'),
                  text: 'Products',
                  checked: location.pathname === '/products',
                  iconProps: { iconName: 'ProductList'},
                  iconOnly: true,
                  disabled: !tenantInfo?.roles.includes('admin')
                }
              ]}
            },
            
          ]
        } />
        
      }
     


    </Stack>
  );
};

