import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import { Stack, Text, getTheme } from '@fluentui/react';
import CountUp from 'react-countup';
import NumberDisplay from './countup';


const Stats = () => {

    const api = useApi();

    const [submittedReports, setSubmittedReports] = useState<number>(0);
    const [draftReports, setDraftReports] = useState<number>(0);
    const [completedReports, setCompletedReports] = useState<number>(0);

    const theme = getTheme();

    const getReports = async () => {

        let submittedUrl = `/reports?status=Submitted`;
        let draftUrl = `/reports?status=Draft`;
        let completedUrl = `/reports?status=Completed`;

        


        try {
            const submittedResult = await api.get(submittedUrl);
            setSubmittedReports(submittedResult.data.count);

            const draftResult = await api.get(draftUrl);
            setDraftReports(draftResult.data.count);

            const completedResult = await api.get(completedUrl);
            setCompletedReports(completedResult.data.count);
          

        } catch (error) {
            console.error(error)
        }


    };

useEffect(() => {

    getReports();
    
},[])

    return (
        <Stack tokens={{ childrenGap: 10}}>
            <Text variant="large">Report Status:</Text>
        
        <Stack horizontal wrap tokens={{ childrenGap: 30}}>
            
            <NumberDisplay bgcolor={theme.palette.blueLight} number={draftReports} text={'Draft'} subtitle='Currently out in the field on a device' />
            <NumberDisplay bgcolor={theme.palette.greenLight} number={submittedReports} text={'Submitted'} subtitle='Uploaded to Revizio, set as Complete on Device ' />
            <NumberDisplay number={completedReports} text={'Archived'} subtitle='Completed reports' />
        </Stack>
        </Stack>
    );
};

export default Stats;
