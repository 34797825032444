import * as Yup from "yup";

export  const schemaTemplateItem = Yup.object().shape({
    label: Yup.string().when('item_type', {
      is: "logic_field",
      then: Yup.string(),
      otherwise: Yup.string().required("You must enter a section label")
    }),
    item_type: Yup.string().required("You must provide an item type"),
    field_type: Yup.string().when('item_type', {
      is: 'field',
      then: Yup.string().required("You must choose a field type")
    }),
    description: Yup.string(),
    field_properties: Yup.object()
      .when('field_type', {
        is: 'boolean',
        then: Yup.object({
          boolean_true_label: Yup.string().required("You must enter a true label"),
          boolean_false_label: Yup.string().required("You must enter a false label"),
        })
      })
      .when('field_type', {
        is: 'number',
        then: Yup.object({
          number_min: Yup.number().required("You must enter a minimum value").lessThan(Yup.ref('number_max'), "Must be less than the maximum").typeError("You must enter a number"),
          number_max: Yup.number().required("You must enter a maximum value").moreThan(Yup.ref('number_min'), "Must be more than the minimum").typeError("You must enter a number")
        })
      })
      .when('field_type', {
        is: 'datetime',
        then: Yup.object({

          date_min: Yup.date().default(null).required("You must enter a minimum date").typeError("You must enter a date"),
          date_max: Yup.date().default(null).required("You must enter a maximum date").typeError("You must enter a date").min(Yup.ref('date_min'), "Max must be greater than min")
        })
      })
      .when('field_type', {
        is: 'select',
        then: Yup.object({
          select_list_id: Yup.string().default(null).required("You must choose a select list")
        })
      })
    ,
    logic_operator: Yup.string().when('item_type', {
      is: 'logic_field',
      then: Yup.string().required("You must choose a logic operator")
    }),
    logic_outcome: Yup.string().when('item_type', {
      is: 'logic_field',
      then: Yup.string().required("You must choose a logic outcome")
    }),
    logic_eval_value: Yup.string().when('item_type', {
      is: 'logic_field',
      then: Yup.string().required("You must choose a logic eval")
    })

  });
