export const FullScreenLoading = () => {
    return (
      <div className="fixed inset-0 h-50 bg-gray-700 flex flex-col justify-center items-center">
        <svg
          className="stroke-current h-20 w-20 animate-spin"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" />
        </svg>
        <p className="text-lg mt-2">Loading...</p>
      </div>
    );
  };
