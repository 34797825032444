import SelectLists from "../components/Configuration/SelectLists"
import PageTitle from "../components/layout/pageTitle"


const SelectListEditor = () => {


    return <> <PageTitle title="Select Lists" /><SelectLists /></>
}

export default SelectListEditor