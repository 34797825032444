
import { Stack, DetailsList, Spinner, Text, Breadcrumb, Separator, IBreadcrumbItem, IconButton, TooltipHost } from "@fluentui/react"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISite } from "../components/Customer/CustomerContentEditPanel";
import PageTitle from "../components/layout/pageTitle";
import useApi from "../hooks/useApi";
import useStateIfMounted from "../hooks/useStateIfMounted";
import { ICustomer } from "../models/ICustomer";
import { IReportTemplate } from "../models/IReportTemplate";
import { globalMessageState } from "../atoms/GlobalMessage";
import { useRecoilState } from "recoil";
import { PanelActionType, PanelActionOwner, FormMode } from "../enums";
import { getSelectItems } from "../components/General/RevizioField";
import { IReport } from "../models/IReport.ts";
import { usePersistedState } from "../hooks/usePersistedState";
import { useId } from "@fluentui/react-hooks";
import { DataTable } from "../components/General/DataTable";
import customers from "./customers";
import { ReportEditPanel } from "../components/Reports/ReportEditPanel";
import dayjs from 'dayjs';
import { useTenantInfo } from "../hooks/useTenantInfo";


const Reports = () => {

    const tenantInfo = useTenantInfo();

    const { id, siteid } = useParams();

    const navigate = useNavigate();

    const [reports, setReports] = useStateIfMounted<IReportTemplate[]>([]);
    const [loading, setLoading] = useStateIfMounted(true);

    const [site, setSite] = useState<ISite>()
    const [customer, setCustomer] = useState<ICustomer>()

    const [, setGlobalMessage] = useRecoilState(globalMessageState)

    // Paging
    const [pageSize, setPageSize] = usePersistedState('CustomerPageSize', 10);
    const [currentPage, setCurrentPage] = usePersistedState('CustomerCurrentPage', 1);

    // Searching
    const [searchTerm, setSearchTerm] = useState("");

    const [totalReports, setTotalReports] = useStateIfMounted<number>(0);

    const [currentReport, setCurrentReport] = useState<IReport>();



    const [isEditReportOpen, setIsEditReportOpen] = useState(false);



    const detailTooltipId = useId('detailTooltip');

    const api = useApi();

    const getCustomer = async () => {

        // Use the Customer ID from properties
        try {


            const result = await api.get(`/customers/${id}`);

            setCustomer(result.data);


        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Customer ${id}`
            });

        }
    }

    const getSite = async () => {

        // Use the Customer ID from properties
        try {


            const result = await api.get(`/sites/${siteid}`);

            setSite(result.data);
        } catch (e) {
            console.error(e);

            setGlobalMessage({
                action: PanelActionType.Error,
                owner: PanelActionOwner.Customer,
                message: `Error Getting Site ${siteid}`
            });

        }
    };

    const getReports = async () => {

      

        let url = `/reports?select=name,report_id,status,created_at,customer_id,site_id,from_template_id&expand=customer,site,template&page=${currentPage}&limit=${pageSize}&search=${searchTerm}&status=Submitted`;

        if (id) {
            url = `/reports?customerId=${id}&select=name,report_id,status,created_at,customer_id,site_id,from_template_id&expand=customer,site,template&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`
        }

        if (siteid) {
            url = `/reports?siteId=${siteid}&select=name,report_id,status,created_at,customer_id,site_id,from_template_id&expand=customer,site,template&page=${currentPage}&limit=${pageSize}&search=${searchTerm}`
        }

        //console.log('url', url)


        try {
            const result = await api.get(url);

          
            setReports(result.data.data);
            setTotalReports(result.data.count)

            setLoading(false);

        } catch (error) {
            console.error(error)
        }


    };

    useEffect(() => {

        if (id) {
            // getCustomer
            getCustomer();
        }

        if (siteid) {
            // getSite
            getSite();

        }
        getReports();


    }, [currentPage, searchTerm, pageSize]);

    function getBreadcrumbItems() {

        let items: IBreadcrumbItem[] = [];

        items.push({
            text: 'Customers', key: 'customers', onClick: () => navigate('/customers')
        });

        customer && items.push(
            {
                text: customer.name, key: 'customer', onClick: () => navigate(`/customer/${id}`)
            });

        site && items.push(
            {
                text: site.name, key: 'site'
            });

        return items;
    }


    return (

        <>
            {loading && <div><Spinner label="loading..." /></div>}
            {!loading &&
                <>
                    

                    <PageTitle title="Submitted Reports" />
                    <Breadcrumb
                items={getBreadcrumbItems()}
                maxDisplayedItems={10}
                ariaLabel="Breadcrumb with items rendered as buttons"
                overflowAriaLabel="More links"
            />
            <Separator />

                    <Stack>
                        {!id && <><Text>To create a new report, go to the Customer Tab and navigate to the site</Text><Separator /></>}
                        
                        <DataTable
                            newButtonText="New Report"
                            hideNewButton={id ? false : true}
                        
                            pageSize={pageSize}
                            columns={
                                [

                                    {
                                        key: 'name',
                                        name: 'Name',
                                        fieldName: 'name',
                                        minWidth: 100
                                    },
                                    {
                                        key: 'customer',
                                        name: 'Customer',
                                        fieldName: 'customer',
                                        minWidth: 250,
                                        onRender: (item) => (
                                            <Text>{item.customer?.name}</Text>
                                        )
                                    },
                                    {
                                        key: 'site',
                                        name: 'Site',
                                        fieldName: 'site',
                                        minWidth: 250,
                                        onRender: (item) => (
                                            <Text>{item.site?.name}</Text>
                                        )
                                    },
                                    
                                    {
                                        key: 'status',
                                        name: 'Status',
                                        fieldName: 'status',
                                        minWidth: 100,
                                        onRender: (item) => (
                                            <Text style={{ color : item.status === 'Completed' ? 'green' : 'black' }} >{item.status}</Text>
                                        )
                                    },
                                    {
                                        key: 'created_at',
                                        name: 'Created Date',
                                        fieldName: 'created_at',
                                        minWidth: 150,
                                        onRender: (item) => (
                                            <Text style={{ color : item.status === 'Completed' ? 'green' : 'black' }} >{dayjs.unix(item.created_at).format('DD/MM/YYYY HH:mm')}</Text>
                                        )
                                    },

                                    {

                                        key: 'actions',
                                        name: 'Actions',
                                        minWidth: 100,
                                        isResizable: false,
                                        onRender: (item) => (
                                            <Stack horizontal>


                                                <TooltipHost
                                                    content="View Report"
                                                    // This id is used on the tooltip itself, not the host
                                                    // (so an element with this id only exists when the tooltip is shown)
                                                    id={detailTooltipId + '-view'}
                                                >

                                                    <IconButton aria-describedby={detailTooltipId + '-view'} iconProps={{ iconName: 'View' }} onClick={() => { navigate(`/report/${item.report_id}`) }
                                                    } />
                                                </TooltipHost>
                                                {item.status === 'Submitted' ? <TooltipHost
                                                    content="Archive Report"
                                                    // This id is used on the tooltip itself, not the host
                                                    // (so an element with this id only exists when the tooltip is shown)
                                                    id={detailTooltipId + '-mark'}
                                                >

                                                    <IconButton aria-describedby={detailTooltipId + '-mark'} iconProps={{ iconName: 'Archive' }} onClick={async () => {

                                                        // API call to update status to complete
                                                        await api.put(`/reports/${item.report_id}`, { status: 'Completed' }).then(result => {
                                                            setLoading(true);
                                                            getReports()
                                                        }).catch(error => {
                                                            console.error(error);
                                                        })
                                                     }
                                                    } />
                                                </TooltipHost> : <TooltipHost
                                                    content="Unarchive Report"
                                                    // This id is used on the tooltip itself, not the host
                                                    // (so an element with this id only exists when the tooltip is shown)
                                                    id={detailTooltipId + '-mark'}
                                                >

                                                    <IconButton disabled={item.status === 'Draft' || tenantInfo?.roles.includes('customer_viewer') } aria-describedby={detailTooltipId + '-mark'} iconProps={{ iconName: 'Undo' }} onClick={ async () => {

                                                         // API call to update status to complete
                                                         await api.put(`/reports/${item.report_id}`, { status: 'Submitted' }).then(result => {
                                                            setLoading(true);
                                                            getReports()
                                                        }).catch(error => {
                                                            console.error(error);
                                                        })
                                                     }
                                                    } />
                                                </TooltipHost>}



                                            </Stack>
                                        ),
                                    },
                                ]
                            }
                            items={reports}
                            totalItems={totalReports}
                            onNewItemClick={() => {
                                setCurrentReport(undefined); setIsEditReportOpen(true)
                            }}
                            onItemClicked={(item: IReport) => navigate(`/report/${item.report_id}`)}
                            currentPage={currentPage}
                            onPaginationClick={(page: number) => {
                                setCurrentPage(page);
                            }}
                            onSearchTextChange={(text: string) => {
                                setCurrentPage(1);
                                setSearchTerm(text);
                            }}
                            onPageSizeChange={(size: number) => {
                                setPageSize(size);
                            }} />

                        <ReportEditPanel formMode={currentReport ? FormMode.Edit : FormMode.Add} isOpen={isEditReportOpen} site_id={site? site.site_id : ''} dismissFunction={() => setIsEditReportOpen(false)} successFunction={() => {
                            setCurrentReport(undefined);
                            setIsEditReportOpen(false);
                            getReports();
                        }} customer_id={customer ? customer.customer_id : ''} report_id={currentReport ? currentReport.report_id : ''} />


                    </Stack>

                </>

            }
        </>

    );
};

export default Reports

