import { useField } from "formik";
import { FC } from "react";
import { IItem } from "../../models/IItem";
import * as _ from "lodash";
import { RevizioField } from "../General/RevizioField";
export interface IReportRenderer {
    isTopLevel: boolean;
    items?: IItem[];
    templateItems: IItem[];
    updateFunction: any;
    shadowSetValueFunction: any;
    shadowGetValueFunction: any;
    initialValues: any;
    validateForm: any;
    addNewSectionFunction: any;
    addNewActionFunction: any;
    parentItem?: IItem;
    setFieldValueFunction: any;
    disabled: boolean;
}

export const GetFieldValue: FC<{ logicItem: IItem, renderer: any }> = (props: { logicItem:IItem, renderer: any }) => {
    // Get the parent to compare to
    const fieldName = props.logicItem.parent_item_id;
    // Using useField from Formik, get the current value
    const [field] = useField(fieldName as string);
    let stringValue = String(field.value).toLowerCase();
    if(stringValue === 'true') {
        stringValue = 'yes'
    }
    if(stringValue === 'false') {
        stringValue = 'no'
    }
    switch(props.logicItem.logic_operator?.toLowerCase()) {
        case 'equal': {
            if (stringValue === props.logicItem.logic_eval_value.toLowerCase()) {
                return props.renderer
            };
            break;
        }
        case 'notequal': {
            if (stringValue != props.logicItem.logic_eval_value.toLowerCase()) {
                return props.renderer
            };
            break;
        }
        case 'in': {
            const testItems: string [] = props.logicItem.logic_eval_value.toLowerCase().split(',').filter((o:string) => {
                return o === stringValue;
            });
            if(testItems.length > 0) {
                return props.renderer
            }
        }
    }
    return null
};

export const ReportRenderer = (props: IReportRenderer) => {
        return props.items ? <RevizioField
            items={props.items}
            updateFunction={props.updateFunction}
            shadowSetFunction={props.shadowSetValueFunction}
            shadowGetFunction={props.shadowGetValueFunction}
            initialValues={props.initialValues}
            validateForm={props.validateForm}
            templateItems={props.templateItems}
            addNewSectionFunction={props.addNewSectionFunction}
            addNewActionFunction={props.addNewActionFunction}
            parentItem={props.parentItem}
            setFieldValue={props.setFieldValueFunction}
            disabled={props.disabled} /> : <></>
}
