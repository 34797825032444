import {
  DetailsList,
  IconButton,
  Panel,
  Stack,
  TextField,
  Text,
  Label,
  PrimaryButton,
  ProgressIndicator,
  ActionButton,
  PanelType,
  StackItem,
  SelectionMode,
  TooltipHost,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton
} from '@fluentui/react';
import { FieldProps } from 'formik'
import * as React from 'react'
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useId } from "@fluentui/react-hooks";
import useApi from '../../hooks/useApi';
import { useRecoilState } from 'recoil';
import { globalMessageState } from '../../atoms/GlobalMessage';
import { BlockBlobClient } from '@azure/storage-blob';
import { PanelActionType, PanelActionOwner } from '../../enums';
import { IDocument, IDocumentUploadResult } from '../../interfaces/IDocument';
import dayjs from 'dayjs';







export type FormikmediaFieldProps<V extends Object, FormValues = any> =
  FieldProps<V, FormValues>

export function FormikMediaField<V extends string, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikmediaFieldProps<V, FormValues>) {

  const [documentEditorIsOpen, setDocumentEditorIsOpen] = useState<boolean>(false);
  const [documentViewerIsOpen, setDocumentViewerIsOpen] = useState<boolean>(false);
  const [documentDeleteIsOpen, setDocumentDeleteIsOpen] = useState<boolean>(false);

  const [currentDocument, setCurrentDocument] = useState<IDocument>();

  const [fileProperties, setFileProperties] = useState<any>(undefined)
  const [dataSize,setDataSize] = useState<number>(0)

  const api = useApi();
  const [, setGlobalMessage] = useRecoilState(globalMessageState)

  const [percentComplete, setPercentComplete] = useState(0);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasCtxRef = React.useRef<CanvasRenderingContext2D | null>(null);
  
  const [imageData,setImageData] = useState<any>();
  
  const dialogContentProps = {

    type: DialogType.normal,
    title: `Delete?`,
    subText: `Are you sure you want to delete '${currentDocument?.name}'?`,

  };

  const modalPropsStyles = { main: { maxWidth: 450 } };

  const modalProps = useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
    }),
    [],
  );

  useEffect(() => {
    if (canvasRef.current) {
      canvasCtxRef.current = canvasRef.current.getContext("2d");
    }
  });


  const onChangeNameTextField = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

      setName(newValue || '');

    },
    [],
  );

  const onChangeDescriptionTextField = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

      setDescription(newValue || '');

    },
    [],
  );

  const _updateProgress = (event: { loadedBytes: number; }) => {
    setPercentComplete(event.loadedBytes / dataSize);
  };


  const handleInputChange = (event: any) => {
    const reader = new FileReader();
    reader.onloadend = (event:any) => {
      const src_image = new Image();

      src_image.onload = () => {
        if (canvasRef.current && canvasCtxRef.current) {

          const ctx = canvasRef.current;

          const message = `${fileProperties.name} TimeStamp: ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`

          ctx.height = src_image.height;
          ctx.width = src_image.width;

          const canvasCtx = canvasCtxRef.current;



          canvasCtx.drawImage(src_image, 0, 0);
          

          // Now lets have a background

          canvasCtx.fillStyle = '#0000FF';

          canvasCtx.fillRect(0,0,1000,200);

          canvasCtx.fillStyle = '#FFFFFF';
          canvasCtx.font = "30px Arial";
          canvasCtx.fillText(`${fileProperties.name} TimeStamp: ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`, 10, 100);
          ctx.toBlob((data) => {
            setImageData(data);

            return;
          });
          
        }
      };
     src_image.src = event.target?.result as string;
    };

    // This will cause the reader to read the file and draw to the canvas etc
    reader.readAsDataURL(event.target.files[0]);

    // Record the properties
    setFileProperties(event.currentTarget.files[0]);
  };
  /*
 "filename": "dave.png",
   "content_type": "image/png",
   "content_length": 1234,
   "name": "Dave",
   "description": "A picture of Dave, when he was all there",
   */
  const uploadToBLOB = async () => {



   
    try {


      const result = await api.post(`/documents`,
        {
          filename: fileProperties.name,
          content_type: fileProperties.type,
          content_length: fileProperties.size,
          name: name,
          description: description
        })

      const documentUploadResult: IDocumentUploadResult = result.data;

      // Lets upload the file to azure
      const blockBlobClient = new BlockBlobClient(
        documentUploadResult.access_url
      );

      await blockBlobClient.uploadData(imageData
      , {
        maxSingleShotSize: 4 * 1024 * 1024,
        onProgress: (ev) => _updateProgress(ev),
      });

      const item_id: string = field.name;

      // NEW VERSION array of 'document_id', id

      //let arr = newField.value ? newField.value.documents : []
      //arr.push({ 'document_id' : 'doc_61b974894df64ab5a591a5cfd2936b91'});

      //form.setFieldValue(field.name, { documents: arr })

      // Need to replace with Formik -> Get Value and not the property as it may be behind
      let oArray = field.value ? (field.value as any).documents : [];

      //let newArray = [...oArray, { 'document_id': documentUploadResult.document_id, 'name': documentUploadResult.name}]
      let newArray = [...oArray, documentUploadResult]

      // field_value = documents [], each one with a 
      //await props.setFieldValueFunction(item_id, { 'documents': newArray }, true)
      form.setFieldValue(field.name, { documents: newArray }, true)

      //setGlobalMessage({
      //action: PanelActionType.Success,
      //owner: PanelActionOwner.Report,
      //message: `Image Uploaded`
      //});

      //props.successFunction({ 'documents': newArray })

      setDocumentEditorIsOpen(false);

    } catch (e) {

      console.error(e)
      setGlobalMessage({
        action: PanelActionType.Error,
        owner: PanelActionOwner.Report,
        message: `Error Uploading Image`
      });

    }


  }

  const deleteDocument = () => {

    const documents = (field.value as any).documents.filter((item: IDocument) => item.document_id != currentDocument?.document_id);

    form.setFieldValue(field.name, { documents: documents }, true)

    setGlobalMessage({
      action: PanelActionType.Success,
      owner: PanelActionOwner.Report,
      message: `Image Deleted`
    });

    setDocumentDeleteIsOpen(false);


  }

  useEffect(() => {

    // Clear out fields on show and hide
    setFileProperties({});
    setName('');
    setDescription('');
    // And open if we've been told to

  }, [documentEditorIsOpen]);

  return (
    <Stack>

      <ActionButton disabled={(props as any).disabled} onClick={() => (

        setDocumentEditorIsOpen(true)

      )} iconProps={{ iconName: 'Add' }}>Add Image</ActionButton>


      {field.value && <DetailsList columns={[
        { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'column2', name: 'Description', fieldName: 'description', minWidth: 100 },
        {

          key: 'actions',
          name: 'Actions',
          minWidth: 100,
          isResizable: false,
          onRender: (item: IDocument) => (
            <Stack horizontal>
              <TooltipHost
                content="View"
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={item.document_id + 'viewdocument'}
              >

                <IconButton aria-describedby={item.document_id + 'viewdocument'} iconProps={{ iconName: 'Handwriting' }} onClick={() => {
                  setCurrentDocument(item); setDocumentViewerIsOpen(true);
                }
                } />
              </TooltipHost>

              <TooltipHost
                content="Delete"
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={item.document_id + '-delete'}
              >

                <IconButton disabled={(props as any).disabled} aria-describedby={item.document_id + '-delete'} iconProps={{ iconName: 'Delete' }} onClick={() => {
                  setCurrentDocument(item); setDocumentDeleteIsOpen(true);
                }
                } />
              </TooltipHost>


            </Stack>
          )
        }
      ]} items={(field.value as any).documents} selectionMode={SelectionMode.none} onItemInvoked={(item: IDocument) => {
        if (!(props as any).disabled) { setCurrentDocument(item); setDocumentViewerIsOpen(true); }
      }} />}

      {/* Add Document Panel */}
      <Panel type={PanelType.large} isOpen={documentEditorIsOpen} onDismiss={() => setDocumentEditorIsOpen(false)} isHiddenOnDismiss={false}>

        <Text>This is the Add Media Panel</Text>
        <Label htmlFor={name}>Friendly Name</Label>
        <TextField name='name' id='name' value={name} onChange={onChangeNameTextField} required />
        <Label htmlFor={description}>Description</Label>
        <TextField name='description' id='description' value={description} onChange={onChangeDescriptionTextField} rows={5} multiline required />
        <Label htmlFor={field.name}>Choose Photo</Label>
        <input accept="image/*;capture=camera" type="file" name={field.name} onChange={handleInputChange} />

        <Stack>
          <ProgressIndicator
            label="Upload Progress"
            description="Displays Upload Progress"
            percentComplete={percentComplete}
          />
        </Stack>
        <PrimaryButton label="Upload" onClick={uploadToBLOB} disabled={description === '' || name === '' || !fileProperties || fileProperties.name === undefined}>Upload</PrimaryButton>

      <canvas id='canvas' ref={canvasRef} style={{ display: 'none'}}></canvas>
  
        {fileProperties && <Stack>
          <p>{fileProperties.name}</p>
          <p>{fileProperties.size}</p>
          <p>{fileProperties.type}</p>

          

         
        </Stack>}


      </Panel>

      {/* View Document Panel */}
      <Panel
        headerText={`${currentDocument?.name}`}
        isOpen={documentViewerIsOpen}
        onDismiss={() => {
          setDocumentViewerIsOpen(false);
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.extraLarge}
        overlayProps={{ isDarkThemed: true }}
        isHiddenOnDismiss={true}
        isLightDismiss={true}

      >
        {currentDocument &&
          <Stack>
            <StackItem>Name: {currentDocument.name}</StackItem>
            <StackItem>Description: {currentDocument.description}</StackItem>
            <StackItem>ID: {currentDocument.document_id}</StackItem>
            <StackItem>Filename: {currentDocument.original_filename}</StackItem>
            <StackItem>Size: {currentDocument.content_length}</StackItem>
            <img src={currentDocument.access_url} width='100%' />
          </Stack>}
      </Panel>

      {/* Delete Document Dialog */}

      <Dialog
        hidden={!documentDeleteIsOpen}
        onDismiss={() => { setDocumentDeleteIsOpen(false) }}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}

      >
        <DialogFooter>
          <TooltipHost content={`This delete cannot be undone`}
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            id={`deleteconfirm`}>
            <PrimaryButton aria-describedby="deleteconfirm" onClick={() => { deleteDocument() }} text="Delete" />
          </TooltipHost>
          <DefaultButton onClick={() => setDocumentDeleteIsOpen(false)} text="Cancel" />
        </DialogFooter>
      </Dialog>

      {/* Also add the view and delete dialogs and then move delete function and view function to here */}

    </Stack>

  )
}

