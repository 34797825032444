import { DefaultButton, IButtonStyles, PrimaryButton, Stack } from '@fluentui/react';
import { DOTS, usePagination } from '../../hooks/usePagination';


export interface IPaginationProps {
  onPageChange: any,
  totalCount: number,
  siblingCount?:number,
  currentPage: number,
  pageSize: number

}

const paginationButtonStyles: IButtonStyles = {
   root: {
    border: 0
   }
}


const Pagination = (props: IPaginationProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });



  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 0;


  return (

    <Stack horizontal>
      <DefaultButton  styles={paginationButtonStyles}  disabled={currentPage === 1} iconProps={{ iconName: 'PageLeft' }} onClick={onPrevious} />
      {paginationRange?.map((pageNumber,index) => {

        if (pageNumber === DOTS) {
          return <PrimaryButton key={index}  text="..." />;
        }

        return (
          <DefaultButton checked={pageNumber === currentPage}
            allowDisabledFocus
            onClick={() => onPageChange(pageNumber)}
            key={index}
            text={pageNumber.toString()}
            styles={
              paginationButtonStyles
            }
            
            
          />
      
        );
      })}
        <DefaultButton styles={paginationButtonStyles} disabled={currentPage === lastPage} iconProps={{ iconName: 'PageRight' }} onClick={onNext} />
    
    </Stack>
    
  );
};

export default Pagination;