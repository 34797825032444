import { useAuth0 } from "@auth0/auth0-react";
import {
  IconButton,
  Label,
  Panel,
  PanelType,
  Persona,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { ITenantInfo } from "../../interfaces/ITenantInfo";
import { LogoutButton } from "../auth0/LogoutButton";

export const ProfileViewPanel = () => {
  const [panelOpen, setPanelOpen] = useState(false);

  const { user } = useAuth0();

  const [loading, setLoading] = useState(true);

  const tenantInfo = useTenantInfo();

  const closePanel = () => setPanelOpen(false);
  const openPanel = () => setPanelOpen(true);

  return (
    <>
      {user && (
        <>
          <Stack horizontal verticalAlign="center">
            <Text>{user.name}</Text>
            <TooltipHost content="My Profile" id={"userPanelButton"}>
              <IconButton
                aria-describedby={"userPanelButton"}
                onClick={openPanel}
                iconProps={{ iconName: "Contact" }}
              ></IconButton>
            </TooltipHost>
          </Stack>
          <Panel
            headerText="My Profile"
            isOpen={panelOpen}
            onDismiss={closePanel}
            closeButtonAriaLabel="Close"
            overlayProps={{ isDarkThemed: true }}
            type={PanelType.medium}
          >
            <Stack
              style={{ marginTop: 10 }}
              tokens={{
                childrenGap: 10,
              }}
            >
              <Stack horizontal>
                <Persona
                  style={{ marginTop: 10 }}
                  imageUrl={user.picture}
                  text={user.name}
                  secondaryText={user.email}
                />
                <LogoutButton />
              </Stack>
              <Label>Tenant ID:</Label>
              <Text>
                <b>{tenantInfo?._id}</b>
              </Text>
              <Label>Tenant ID (Full):</Label>
              <Text>
                <b>{tenantInfo?.tenantId}</b>
              </Text>
              <Label>Tenant Name:</Label>
              <Text>
                <b>{tenantInfo?.name}</b>
              </Text>
              <Label>Roles:</Label>
              {tenantInfo?.roles.map((role, index) => (
                <Text key={index}>
                  <b>{role}</b>
                </Text>
              ))}
              <Label>Customer:</Label>
              {
                <Text>
                  <b>{tenantInfo?.customerId}</b>
                </Text>
              }
              
            </Stack>
          </Panel>
        </>
      )}
    </>
  );
};
